<template>
  <v-app :style="colorVapp">
    <!-- <div id="app"> ADD -->
    <div>
      <!-- @advancesearch="fn_advancesearch" -->
      <onebox_toolbar
        :quicksearch="true"
        :parentfolder="parentfolder"
        :permissionfolder="permission_currentfolder"
        :departmentid="departmentid"
        :heardertextsearch="heardertextsearch"
        @inputdata="filteredItems"
        @loadfile="loadsharefolderpagination(true)"
        @callparentfolder="sendParentfolder()"
        @callstorage="loadstorage()"
        @advancesearch="fn_advancesearch"
        @open_folder="fn_open_folder"
      ></onebox_toolbar>
      <div v-if="resolutionScreen < 500 && permission_currentfolder !== '01' && permission_currentfolder !== '02'">
        <floatingbutton
          :departmentid="departmentid"
          @loadfile="loadsharefolderpagination(true), loadstorage()"
        />
      </div>
      <v-content>
        <v-card class="elevation-0">
          <!-- <v-divider></v-divider> -->
          <v-overlay :value="processloader" absolute :color="color.BG" dark>
            <v-progress-circular
              indeterminate
              size="64"
              :color="color.theme"
            ></v-progress-circular>
            <br />
            <br />
            <span :style="colorProgress">&nbsp; &nbsp; loading</span>
          </v-overlay>
          <!-- <v-toolbar dense class="elevation-0">
            <v-avatar color="primary" size="35">
              <v-icon dark size="20">folder</v-icon>
            </v-avatar>
            <v-toolbar-title>
              <v-breadcrumbs :items="navigate" divider=">">
                <template v-slot:item="props">
                  <v-breadcrumbs-item
                    style="cursor: pointer;"
                    @click="gotofolder(props.item.text[1])"
                  >
                    <span
                      style="color: #1565C0; font-size: 18px; font-weight: 600"
                    >{{ props.item.text[0] }}</span>
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>
             
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn fab small text @click="backtopreventfolder()" color="primary">
                <v-icon>keyboard_backspace</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>-->

          <!-- <span style="color: #1565C0; font-weight: 600;">&nbsp;{{ foldername }}</span> -->
          <!-- Header Page lg -->
          <v-list nav :color="color.BG" v-if="resolutionScreen >= 500">
            <v-list-item class="text-left" v-if="heardertextsearch === false">
              <v-btn
                @mouseover="show_back = true"
                @mouseleave="show_back = false"
                fab
                small
                :color="color.theme"
                @click="backtopreventfolder()"
                class="ma-2"
                v-if="this.navigate.length > 1"
              >
                <v-icon color="white">keyboard_backspace</v-icon>
                <v-badge
                  v-if="$t('default') === 'th'"
                  style="z-index:10000;bottom:-45px;left:-35px;"
                  :value="show_back"
                  :content="$t('balloon.back')"
                  transition="slide-y-transition"
                ></v-badge>
                <v-badge
                  v-else
                  style="z-index:10000;bottom:-45px;left:-27px;"
                  :value="show_back"
                  :content="$t('balloon.back')"
                  transition="slide-y-transition"
                ></v-badge>
              </v-btn>
              <v-list-item-avatar class="text-center">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="20">folder_shared</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-title class="pt-1 pb-1">
                <v-breadcrumbs :items="navigate" divider=">" class="pa-0">
                  <template v-slot:item="props">
                    <v-btn rounded text>
                      <v-breadcrumbs-item
                        style="cursor: pointer;"
                        :disabled="loaddataprogress"
                        @click.stop="gotofolder(props.item.text[1])"
                      >
                        <span v-if="props.item.text[2]" :style="headerPage">
                          <span
                            v-if="$t('default') === 'en'"
                            :style="headerPage"
                          >
                            {{ props.item.text[0] }}
                          </span>
                          <span
                            v-else-if="$t('default') === 'th'"
                            :style="headerPage"
                          >
                            {{ props.item.text[2] }}
                          </span>
                        </span>
                        <span v-else :style="headerPage">
                          {{ props.item.text[0] }}
                        </span>
                      </v-breadcrumbs-item>
                    </v-btn>
                  </template>
                </v-breadcrumbs>
              </v-list-item-title>
              <!-- <v-list-item-action>
                <v-btn 
                @mouseover="show_back = true"
                @mouseleave="show_back = false"
                fab 
                small 
                @click="backtopreventfolder()">
                  <v-icon :color="color.theme">keyboard_backspace</v-icon>
                  <v-badge v-if="$t('default') === 'th'"
                       style="z-index:10000;bottom:-45px;left:-38px;"
                       :value="show_back"
                       :content="$t('balloon.back')"
                       transition="slide-y-transition"
                    ></v-badge>
                    <v-badge v-else
                       style="z-index:10000;bottom:-45px;left:-28px;"
                       :value="show_back"
                       :content="$t('balloon.back')"
                       transition="slide-y-transition"
                    ></v-badge>
                </v-btn>
              </v-list-item-action> -->
            </v-list-item>
            <v-list-item class="text-left" v-else>
              <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-magnify</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage"
                  >&nbsp;{{ $t("tablefile.searchtext") }}</span
                >
              </v-list-item-title>
            </v-list-item-content>
   
            </v-list-item>
          </v-list>
          <!-- Header Page xs -->
          <v-list nav :color="color.BG" class="mb-n2" v-else>
            <v-list-item class="text-left" v-if="heardertextsearch === false">
            <v-chip :style="headerChipPage" :color="$vuetify.theme.dark ? '#989898' : 'white'">
              <v-list-item-avatar class="text-center" v-if="this.navigate.length > 1" size="20">
                <v-btn
                  elevation="1"
                  fab
                  class="text-center" v-if="this.navigate.length > 1"
                  :color="$vuetify.theme.dark ? 'white' : color.theme"
                  @click="backtopreventfolder(),show_back = false"
                >
                  <v-icon :color="$vuetify.theme.dark ? '#989898' : 'white'" dark size="16">keyboard_backspace</v-icon>
                </v-btn>
              </v-list-item-avatar>
              <v-list-item-avatar class="text-center" v-else>
                <v-icon :color="$vuetify.theme.dark ? 'white' : color.theme" dark size="20">folder_shared</v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="ml-n2">
                <v-breadcrumbs :items="navigate" divider=">" class="pa-0">
                  <template v-slot:item="props">
                    <v-breadcrumbs-item
                      style="cursor: pointer;"
                      :disabled="loaddataprogress"
                      @click.stop="gotofolder(props.item.text[1])"
                    >
                      <span v-if="props.item.text[2]" :style="headerPageMobile" :color="$vuetify.theme.dark ? 'white' : color.theme">
                        <span
                          v-if="$t('default') === 'en'"
                          :style="headerPageMobile"
                          :color="$vuetify.theme.dark ? 'white' : color.theme"
                        >
                          {{ props.item.text[0] }}
                        </span>
                        <span
                          v-else-if="$t('default') === 'th'"
                          :style="headerPageMobile"
                          :color="$vuetify.theme.dark ? 'white' : color.theme"
                        >
                          {{ props.item.text[2] }}
                        </span>
                      </span>
                      <span v-else :style="headerPageMobile" :color="$vuetify.theme.dark ? 'white' : color.theme">
                        {{ props.item.text[0] }}
                      </span>
                    </v-breadcrumbs-item>
                  </template>
                </v-breadcrumbs>
              </v-list-item-title>
            </v-chip>
            </v-list-item>
            <v-list-item class="text-left" v-else>
              <v-chip :style="headerChipPage" :color="$vuetify.theme.dark ? '#989898' : 'white'">
              <v-list-item-avatar class="text-center">
                <v-icon :color="$vuetify.theme.dark ? 'white' : color.theme" dark size="20">mdi-magnify</v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="ml-n2">
                <span :style="headerPageMobile">&nbsp;{{ $t("tablefile.searchtext") }}</span>
              </v-list-item-title>
            </v-chip> 
            <!-- <v-chip :style="headerChipPage" :color="$vuetify.theme.dark ? '#989898' : 'white'">
              <v-list-item-avatar class="text-center" v-if="this.navigate.length > 1" size="20">
                <v-btn
                  elevation="1"
                  fab
                  class="text-center" v-if="this.navigate.length > 1"
                  :color="$vuetify.theme.dark ? 'white' : color.theme"
                  @click="backtopreventfolder(),show_back = false"
                >
                  <v-icon :color="$vuetify.theme.dark ? '#989898' : 'white'" dark size="16">keyboard_backspace</v-icon>
                </v-btn>
              </v-list-item-avatar>
              <v-list-item-avatar class="text-center" v-else>
                <v-icon :color="$vuetify.theme.dark ? 'white' : color.theme" dark size="20">folder_shared</v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="ml-n2">
                <v-breadcrumbs :items="navigate" divider=">" class="pa-0">
                  <template v-slot:item="props">
                    <v-breadcrumbs-item
                      style="cursor: pointer;"
                      :disabled="loaddataprogress"
                      @click.stop="gotofolder(props.item.text[1])"
                    >
                      <span v-if="props.item.text[2]" :style="headerPageMobile" :color="$vuetify.theme.dark ? 'white' : color.theme">
                        <span
                          v-if="$t('default') === 'en'"
                          :style="headerPageMobile"
                          :color="$vuetify.theme.dark ? 'white' : color.theme"
                        >
                          {{ props.item.text[0] }}
                        </span>
                        <span
                          v-else-if="$t('default') === 'th'"
                          :style="headerPageMobile"
                          :color="$vuetify.theme.dark ? 'white' : color.theme"
                        >
                          {{ props.item.text[2] }}
                        </span>
                      </span>
                      <span v-else :style="headerPageMobile" :color="$vuetify.theme.dark ? 'white' : color.theme">
                        {{ props.item.text[0] }}
                      </span>
                    </v-breadcrumbs-item>
                  </template>
                </v-breadcrumbs>
              </v-list-item-title>
            </v-chip> -->
            </v-list-item>
          </v-list>

          <!-- <dialog_filter 
          :system="system" 
          :status_aspect_key="status_aspect_key"
          @datafilter="loadfilterdata"
          :folderid_yourfolder="data_doctype"
          ></dialog_filter> -->
          <!-- <v-divider></v-divider> -->

          <v-card
            class="elevation-0"
            :color="color.BG"
            id="thiscontainer_directorysharefile"
            @contextmenu="show"
          >
            <v-card-text class="pa-0">
              <v-container fluid>
                <!-- <v-breadcrumbs :items="navigate" divider=">">
                    <template v-slot:item="props">
                      <v-breadcrumbs-item
                        style="cursor: pointer;"
                        @click="gotofolder(props.item.text[1])"
                      ><v-btn text color="blue darken-3" class="pa-0"><span style="font-size:  15px; font-weight: 600;">{{ props.item.text[0] }}</span></v-btn></v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>-->
                <!-- <v-layout row wrap justify-center>
                    <v-flex lg6 class="pa-6">
                       <h2><v-icon x-large>folder</v-icon> &nbsp; &nbsp;{{ foldername }}</h2>
                    </v-flex>
                    <v-flex lg12 class="pa-4 text-right">
                        <v-btn fab small color="primary" @click="backtoroot()"><v-icon> home </v-icon></v-btn>
                    </v-flex>
                </v-layout>-->
                <v-layout row wrap justify-center>
                  <!-- <v-flex lg12 class="pa-4 text-right">
                        <v-btn fab small color="primary" @click="backtoroot()"><v-icon> home </v-icon></v-btn>
                  </v-flex>-->
                  <v-flex lg12 class="ma-4" v-if="loaddataprogress === true">
                    <div class="text-center">
                      <v-progress-circular
                        :size="50"
                        :color="color.theme"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </v-flex>
                  <v-flex xs12 lg12 class="pa-4" v-else>
                    <v-layout
                      row
                      wrap
                      class="pl-3 pr-3"
                      v-if="resolutionScreen >= 500"
                    >
                      <v-flex lg2 xs4>
                        <v-select
                          prepend-inner-icon="sort"
                          hide-details
                          outlined
                          solo
                          dense
                          v-model="size"
                          :items="listsize"
                          class="text-center"
                          :item-color="color.theme"
                          :color="color.theme"
                          @change="loadsharefolderpagination(true);"
                        >
                          <template v-slot:item="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                        </v-select>
                      </v-flex>
                      <v-flex lg2 xs4>
                        <v-select
                          return-object
                          hide-details
                          outlined
                          solo
                          dense
                          v-model="typesharefile"
                          :items="listtypesharefile"
                          class="text-center pl-2"
                          :item-color="color.theme"
                          :color="color.theme"
                          @change="loadsharefolderpagination(true);"
                        >
                          <template v-slot:item="{ item }">
                            <span style="font-size: 15px;">{{
                              $t(item.text)
                            }}</span>
                          </template>
                          <template v-slot:selection="{ item }">
                            <span style="font-size: 15px;">{{
                              $t(item.text)
                            }}</span>
                          </template>
                          <!-- <template v-slot:item="{ item }">
                            <div class="text-center" @click="loadfolder()">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                              <template v-slot:selection="{ item }">
                            <div class="text-center" @click="loadfolder()">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template> -->
                        </v-select>
                      </v-flex>
                      <v-flex
                        lg4
                        offset-lg6
                        class="pl-3 pr-3 hidden-md-and-down"
                      >
                        <v-alert
                          dense
                          height="41"
                          v-model="clickSort"
                          :color="color.alertSort"
                          dark
                          v-show="clickSort"
                          style="margin-bottom: 0%;"
                        >
                          <div>
                            <v-icon
                              :color="color.alertText"
                              v-if="multiSort === true"
                              >mdi-chevron-up</v-icon
                            >
                            <v-icon
                              :color="color.alertText"
                              v-else-if="multiSort === false"
                              >mdi-chevron-down</v-icon
                            >
                            <span :style="colorSort" v-if="sortby === 'name'">{{
                              $t("Filter.name")
                            }}</span>
                            <span
                              :style="colorSort"
                              v-else-if="sortby === 'date'"
                              >{{ $t("Filter.date") }}</span
                            >
                            <span
                              :style="colorSort"
                              v-else-if="sortby === 'size'"
                              >{{ $t("Filter.size") }}</span
                            >
                            <span
                              :style="colorSort"
                              v-else-if="sortby === 'owner'"
                              >{{ $t("Filter.owner") }}</span
                            >
                            <v-btn
                              width="24"
                              height="24"
                              style="float: right;"
                              text
                              fab
                              x-small
                              :color="color.alertSort"
                              dark
                              @click="checkSort"
                            >
                              <v-icon :color="color.alertText"
                                >mdi-close-circle</v-icon
                              >
                            </v-btn>
                          </div>
                        </v-alert>
                      </v-flex>
                      <v-spacer></v-spacer>
                      <v-flex>
                        <!-- Sort File ใหม่ -->
                        <v-list nav :color="color.BG" class="py-0">
                          <v-list-item>
                            <v-layout>
                              <v-flex class="text-right" lg12>
                                <v-menu offset-y rounded="lg">
                                  <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                      small
                                      rounded
                                      class="white--text"
                                      :color="color.theme"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small>mdi-sort</v-icon>
                                      {{ $t("sorting.sort") }}:
                                      <div class="font-weight-bold">
                                        {{
                                          $t(
                                            sorting["sort"].find(
                                              (x) => x.id === sortAndDirection.sort_by
                                            ).text
                                          )
                                        }},
                                        {{
                                          $t(
                                            sorting["direction"].find(
                                              (x) => x.id === sortAndDirection.direction
                                            ).text
                                          )
                                        }}
                                      </div>
                                    </v-btn>
                                  </template>
                                  <v-list dense nav>
                                    <v-list-item-group
                                      v-model="sortAndDirection.sort_by"
                                      active-class="border"
                                      :color="color.theme"
                                      mandatory
                                    >
                                      <template
                                        v-for="(item, i) in sorting['sort']"
                                      >
                                        <v-list-item
                                          :key="`item-${i}`"
                                          :value="item.id"
                                          @click="sortAndDirection.sort_by === item.id ? reloadSortByAndDirection() : ''"
                                        >
                                          <template>
                                            <v-list-item-content>
                                              <v-list-item-title>{{
                                                $t(item.text)
                                              }}</v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-list-item>
                                      </template>
                                    </v-list-item-group>
                                    <v-divider class="mt-2" />
                                    <v-list-item-group
                                      class="mt-4"
                                      v-model="sortAndDirection.direction"
                                      active-class="border"
                                      :color="color.theme"
                                      mandatory
                                    >
                                      <template
                                        v-for="(item, i) in sorting['direction']"
                                      >
                                        <v-list-item
                                          :key="`item-${i}`"
                                          :value="item.id"
                                          @click="sortAndDirection.direction === item.id ? reloadSortByAndDirection() : ''"
                                        >
                                          <template>
                                            <v-list-item-content>
                                              <v-list-item-title>{{
                                                $t(item.text)
                                              }}</v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-list-item>
                                      </template>
                                    </v-list-item-group>
                                  </v-list>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-list-item>
                        </v-list>
                      </v-flex>
                    </v-layout>
                    <!-- <br> -->
                    <v-layout v-if="resolutionScreen >= 500">
                      <v-flex xs4 lg4>
                        <div
                          v-if="statusmutipledownload === false"
                          class="pt-2 hidden-md-and-down"
                        >
                          <v-btn
                            @mouseover="show_multipledownload = true"
                            @mouseleave="show_multipledownload = false"
                            :color="color.theme"
                            rounded
                            :dark="color.darkTheme"
                            class="ma-1"
                            @click="
                              (statusmutipledownload = true),
                                (show_multipledownload = false)
                            "
                            fab
                            small
                          >
                            <v-icon>mdi-download-multiple</v-icon>&nbsp;
                            <!-- {{ $t('myinboxPage.multipledownload')}} -->
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_multipledownload"
                              :content="$t('balloon.menu_download')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_multipledownload"
                              :content="$t('balloon.menu_download')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                        </div>
                        <div v-else class="pt-2">
                          <v-btn
                            @mouseover="show_download = true"
                            @mouseleave="show_download = false"
                            fab
                            small
                            color="primary"
                            class="ma-1"
                            @click="multipledownload()"
                          >
                            <v-icon>mdi-download-multiple</v-icon>
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_download"
                              :content="$t('balloon.download')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_download"
                              :content="$t('balloon.download')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                          <v-btn
                            @mouseover="show_clear = true"
                            @mouseleave="show_clear = false"
                            fab
                            small
                            color="error"
                            class="ma-1 elevation-3"
                            @click="
                              (statusmutipledownload = false),
                                clearmultipledownload(),
                                (show_clear = false)
                            "
                          >
                            <v-icon>clear</v-icon>
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-20px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-flex xs8 lg8 class="text-right" v-if="heardertextsearch === true">
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4 white--text" :color="color.theme">{{ $t("count_data_Advasearch") }} : {{ count_data_adv_ }}</v-chip>
                        <!-- <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>folder</v-icon>
                          {{ countfolder }}
                          {{ countfolder > 1 ? $t("folders") : $t("folder") }}
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>mdi-file</v-icon>
                          {{ countfile }}
                          {{ countfile > 1 ? $t("files") : $t("file") }}
                        </v-chip> -->
                      </v-flex>
                      <v-flex xs8 lg8 class="text-right" v-else>
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>folder</v-icon>
                          {{ countfolder }}
                          {{ countfolder > 1 ? $t("folders") : $t("folder") }}
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>mdi-file</v-icon>
                          {{ countfile }}
                          {{ countfile > 1 ? $t("files") : $t("file") }}
                        </v-chip>
                      </v-flex>
                    </v-layout>
                    <!-- หน้าจอxs -->
                    <v-layout row wrap class="mt-n4" v-else>
                      <v-flex xs3>
                        <!-- <v-select
                          prepend-inner-icon="sort"
                          hide-details
                          outlined
                          solo
                          dense
                          v-model="size"
                          :items="listsize"
                          class="text-center ma-1 ml-3"
                        >
                          <template v-slot:item="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                        </v-select> -->
                        <v-menu offset-y>
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn small rounded
                              :color="color.theme" 
                              v-bind="attrs"
                              v-on="on"
                              text
                              style="font-size:16px; letter-spacing: 0px;"
                              class="ml-0"
                            >
                              <v-icon class="mr-2">sort</v-icon><span class="font-weight-bold" :color="color.theme">{{listsizemenu.find(x => x.value === size).value}}</span>
                            </v-btn>
                          </template>
                          <v-list dense nav :style="roundListSort">
                            <v-list-item-group
                              v-model="size"
                              :color="color.theme"
                            >
                              <template  v-for="(item, i) in listsizemenu">
                                <v-list-item
                                  :key="`item-${i}`"
                                  :value="item.value"
                                >
                                  <template>
                                    <v-list-item-content>
                                      <v-list-item-title :style="size === item.value ? listOnBold : listNoBold" >{{ item.value }}</v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </template>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </v-flex>
                      <v-flex xs9 class="text-right ml-n3"  v-if="heardertextsearch === true">
                        <!-- <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>folder</v-icon>
                          <span
                            >{{ countfolder }}
                            <span v-if="resolutionScreen >= 400">{{
                              countfolder > 1 ? $t("folders") : $t("folder")
                            }}</span></span
                          >
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>mdi-file</v-icon>
                          <span
                            >{{ countfile }}
                            <span v-if="resolutionScreen >= 400">{{
                              countfile > 1 ? $t("files") : $t("file")
                            }}</span></span
                          >
                        </v-chip> -->
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4 white--text" :color="color.theme">{{ $t("count_data_Advasearch") }} : {{ count_data_adv_ }}</v-chip>
                        <!-- <v-chip style="height: 32px; border-radius: 32px;" class="px-4 mr-2" color="#1876D1" :text-color="color.chipText">
                          <v-icon center size="18" class="mr-2">folder</v-icon>
                          <span style="font-weight: 700; font-size:16px;"
                            >{{ countfolder }}
                            <span v-if="resolutionScreen >= 400">{{
                              countfolder > 1 ? $t("folders") : $t("folder")
                            }}</span></span
                          >
                        </v-chip>
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4" color="#5AB685" :text-color="color.chipText">
                          <v-icon center size="18" class="mr-2">mdi-file-document</v-icon>
                          <span style="font-weight: 700; font-size:16px;"
                            >{{ countfile }}
                            <span v-if="resolutionScreen >= 400">{{
                              countfile > 1 ? $t("files") : $t("file")
                            }}</span></span
                          >
                        </v-chip> -->
                      </v-flex>
                      <v-flex xs9 class="text-right ml-n3"  v-else>
                        <!-- <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>folder</v-icon>
                          <span
                            >{{ countfolder }}
                            <span v-if="resolutionScreen >= 400">{{
                              countfolder > 1 ? $t("folders") : $t("folder")
                            }}</span></span
                          >
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>mdi-file</v-icon>
                          <span
                            >{{ countfile }}
                            <span v-if="resolutionScreen >= 400">{{
                              countfile > 1 ? $t("files") : $t("file")
                            }}</span></span
                          >
                        </v-chip> -->
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4 mr-2" color="#1876D1" :text-color="color.chipText">
                          <v-icon center size="18" class="mr-2">folder</v-icon>
                          <span style="font-weight: 700; font-size:16px;"
                            >{{ countfolder }}
                            <span v-if="resolutionScreen >= 400">{{
                              countfolder > 1 ? $t("folders") : $t("folder")
                            }}</span></span
                          >
                        </v-chip>
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4" color="#5AB685" :text-color="color.chipText">
                          <v-icon center size="18" class="mr-2">mdi-file-document</v-icon>
                          <span style="font-weight: 700; font-size:16px;"
                            >{{ countfile }}
                            <span v-if="resolutionScreen >= 400">{{
                              countfile > 1 ? $t("files") : $t("file")
                            }}</span></span
                          >
                        </v-chip>
                      </v-flex>
                      <v-flex xs6 class="mt-4">
                        <!-- <v-select
                          return-object
                          hide-details
                          outlined
                          solo
                          dense
                          v-model="typesharefile"
                          :items="listtypesharefile"
                          class="text-center ma-1 mr-2 ml-2"
                          :item-color="color.theme"
                          :color="color.theme"
                          @change="loadfolder()"
                        >
                          <template v-slot:item="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{
                                $t(item.text)
                              }}</span>
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{
                                $t(item.text)
                              }}</span>
                            </div>
                          </template>
                        </v-select> -->
                        <v-menu offset-y :return-value="typesharefile">
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn small rounded
                              :color="color.theme" 
                              v-bind="attrs"
                              v-on="on"
                              text
                              style="font-size:16px; letter-spacing: 0px;"
                              class="ml-0"
                            >
                              <v-icon v-if="typesharefile.key === 'S'" class="mr-2">mdi-eye</v-icon>
                              <v-icon v-else class="mr-2">mdi-eye-off</v-icon>
                              <span class="font-weight-bold" :color="color.theme"><pre>{{$t(typesharefile.text)}}</pre></span>
                            </v-btn>
                          </template>
                          <v-list dense nav :style="roundListSort">
                            <v-list-item-group
                              v-model="typesharefile"
                              :color="color.theme"
                              @change="loadsharefolderpagination(true)"
                            >
                              <template  v-for="(item, i) in listtypesharefile">
                                <v-list-item
                                  :key="`item-${i}`"
                                  :value="item"
                                >
                                  <template>
                                    <v-list-item-content>
                                      <v-list-item-title :style="typesharefile.key === item.key ? listOnBold : listNoBold" >{{ $t(item.text) }}</v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </template>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </v-flex>
                      <v-flex xs6 class="text-center">
                      </v-flex>
                    </v-layout>

                    <br />
                    <v-card v-if="resolutionScreen < 500" class="mt-n2" :style="displayMobile">
                      <v-list
                        two-line
                        class="elevation-0 px-2"
                        v-if="rootfile.length !== 0"
                        :style="styleDisplayMobile"
                      >
                        <v-list-item
                          v-for="item in paginatedData"
                          :key="item.title"
                          @contextmenu="test($event, item)"
                          :style="displayMobileForListItem"
                        >
                          <v-list-item-action>
                            <v-badge
                              color="orange"
                              overlap
                              icon="link"
                              v-if="
                                item.file_status_sharelink === 'Y' ||
                                  item.file_status_sharelink === 'P'
                              "
                            >
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                            <v-badge
                              color="red"
                              overlap
                              icon="lock"
                              v-else-if="item.editing_status === 'Y'">
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                            <v-badge
                              color="red"
                              overlap
                              icon="mdi-sync-circle"
                              v-else-if="item.editing_status === 'S'">
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                            <v-icon v-else large :color="item.file_icon[1]">{{
                              item.file_icon[0]
                            }}</v-icon>
                          </v-list-item-action>

                          <v-list-item-content
                            @dblclick="
                              gotodirectorysharefile(
                                item.file_id,
                                item.file_type,
                                item
                              )
                            "
                            @click="
                              gotodirectorysharefile(
                                item.file_id,
                                item.file_type,
                                item
                              )
                            "
                          >
                            <v-list-item-title style="font-size: 14px;"
                              ><v-icon
                                v-if="item.status_lock_data === 'Y'"
                                color="error"
                                small
                                >lock</v-icon
                              >
                              <div style="display: inline-block;" 
                              v-if="item.status_hilightword === undefined">
                              {{ item.file_name }}
                              </div>
                              <div style="display: inline-block;" 
                              v-html="fn_hilightWord(item.file_name)" v-else>
                              </div>
                              </v-list-item-title
                            >
                            <!-- <v-icon class="pr-1" color="red" x-small v-if="item.relation_status === 0">device_hub</v-icon>
                              <v-icon class="pr-1" color="amber accent-4" x-small v-else-if="item.relation_status === 1">device_hub</v-icon> -->
                            <v-list-item-subtitle style="font-size: 12px;"
                              v-text="calculatesize(item.file_size)"
                            ></v-list-item-subtitle>
                            <v-list-item-subtitle v-show="item.editing_status === 'Y'">
                              <v-chip x-small color="primary" v-if="$t('default') === 'th'">{{ item.last_update_name.name_th }} กำลังแก้ไข</v-chip>
                              <v-chip x-small color="primary" v-else>{{ item.last_update_name.name_eng }} editting</v-chip>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-show="item.editing_status === 'S'">
                              <v-chip x-small color="primary" v-if="$t('default') === 'th'"> กำลังซิงค์...</v-chip>
                              <v-chip x-small color="primary" v-else>Syncing...</v-chip>
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn
                              class="elevation-0"
                              icon
                              fab
                              small
                              @click="test($event, item)"
                            >
                              <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <!-- 
                        <v-divider inset></v-divider>-->
                      </v-list>
                      <!-- <v-list v-else class="elevation-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-center"
                              v-text="$t('tablefile.empty')"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list> -->
                      <v-card-text class="mt-4 mb-4" v-else>
                        <v-img
                          :src="require('../assets/img/icon_empty_data.png')"
                          max-width="132"
                          max-height="150"
                          class="mx-auto"
                        />
                        <v-list-item-title
                          :style="'font-size: 16px; font-weight: 700; line-height: 24px; color:' + color.theme + ';'"
                          class="text-center mt-8"
                        > {{$t('tablefile.empty')}} </v-list-item-title>
                      </v-card-text>
                    </v-card>
                    <div v-else>
                      <v-data-table
                        v-model="filemultipledownload"
                        :headers="headers"
                        :items="rootfile"
                        :search="search"
                        :single-select="false"
                        item-key="file_id"
                        :show-select="statusmutipledownload"
                        :no-data-text="$t('tablefile.empty')"
                        class="elevation-0"
                        :page.sync="page"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                      >
                        <!-- <template v-slot:top>
                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                        </template>-->
                        <template v-slot:[`header.file_type`]="{ header }">
                          <span :style="headerTable">{{ header.text }}</span>
                        </template>
                        <template v-slot:[`header.file_name`]="{ header }">
                          <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'name'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_owner`]="{ header }">
                          <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'owner'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_size`]="{ header }">
                          <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'size'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_lastdtm`]="{ header }">
                          <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'date'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.permission`]="{ header }">
                          <span
                            class="pointer text-center"
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_status`]="{ header }">
                          <span :style="headerTable">{{
                            $t(header.text)
                          }}</span>
                        </template>

                        <template v-slot:item="props">
                          <tr
                            style="cursor: pointer"
                            @contextmenu="test($event, props.item)"
                            @dblclick="
                              gotodirectorysharefile(
                                props.item.file_id,
                                props.item.file_type,
                                props.item
                              )
                            "
                          >
                            <!-- <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox v-model="filemultipledownload" :value="props.item" />
                            </td>-->
                            <td
                              class="text-center"
                              v-show="statusmutipledownload"
                            >
                              <v-checkbox
                                v-model="filemultipledownload"
                                :value="props.item"
                              />
                            </td>
                            <td width="7%" class="text-center">
                              <v-badge
                                color="orange"
                                overlap
                                icon="link"
                                v-if="
                                  props.item.file_status_sharelink === 'Y' ||
                                    props.item.file_status_sharelink === 'P'
                                "
                              >
                                <v-icon
                                  large
                                  :color="props.item.file_icon[1]"
                                  >{{ props.item.file_icon[0] }}</v-icon
                                >
                              </v-badge>
                              <v-badge color="red" overlap icon="lock" v-else-if="props.item.editing_status === 'Y'">
                                <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                              </v-badge>
                              <v-badge color="red" overlap icon="mdi-sync-circle" v-else-if="props.item.editing_status === 'S'">
                                <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                              </v-badge>
                              <v-icon
                                v-else
                                large
                                :color="props.item.file_icon[1]"
                                >{{ props.item.file_icon[0] }}</v-icon
                              >
                            </td>
                            <td width="20%" :title="props.item.file_name">
                              <div
                                style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis; width:300px"
                              >
                                <v-icon
                                  v-if="props.item.status_lock_data === 'Y'"
                                  color="error"
                                  small
                                  >lock</v-icon
                                >
                                <div :id="`text-td-${props.item.file_id}`" 
                                  style="display: inline-block; margin-left: 5px;"
                                  v-if="props.item.status_hilightword === undefined">
                                {{ props.item.file_name }}
                                </div>
                                <div v-html="fn_hilightWord(props.item.file_name)" style="display: inline-block; margin-left: 5px;" v-else>
                                </div>
                                <div v-show="props.item.editing_status === 'Y'">
                                <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                  >{{ props.item.last_update_name.name_th }} กำลังแก้ไข</v-chip
                                >
                                <v-chip x-small color="primary" v-else
                                  >{{ props.item.last_update_name.name_eng }} editting</v-chip
                                >
                              </div>
                              <div v-show="props.item.editing_status === 'S'">
                                <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                  >กำลังซิงค์...</v-chip
                                >
                                <v-chip x-small color="primary" v-else
                                  >Syncing...</v-chip
                                >
                              </div>
                              </div>
                              <v-icon
                                class="pr-1"
                                color="red"
                                x-small
                                v-if="props.item.relation_status === 0"
                                >device_hub</v-icon
                              >
                              <v-icon
                                class="pr-1"
                                color="amber accent-4"
                                x-small
                                v-else-if="props.item.relation_status === 1"
                                >device_hub</v-icon
                              >
                            </td>
                            <td width="15%" v-if="$t('default') === 'th'">
                              {{ props.item.file_owner_th }}
                              <v-icon
                                small
                                color="orange"
                                v-if="props.item.file_permission === 'V'"
                                >mdi-eye</v-icon
                              >
                              <v-icon
                                small
                                color="orange"
                                v-if="props.item.file_permission === 'E'"
                                >mdi-pencil</v-icon
                              >
                            </td>
                            <td width="15%" v-else>
                              {{ props.item.file_owner_eng }}
                              <v-icon
                                small
                                color="orange"
                                v-if="props.item.file_permission === 'V'"
                                >mdi-eye</v-icon
                              >
                              <v-icon
                                small
                                color="orange"
                                v-if="props.item.file_permission === 'E'"
                                >mdi-pencil</v-icon
                              >
                            </td>
                            <td width="15%">
                              {{ formatdatetime(props.item.file_lastdtm) }}
                            </td>
                            <td width="12%">
                              {{ calculatesize(props.item.file_size) }}
                            </td>
                            <td width="15%" class="text-center">
                              {{
                                props.item.permission_account === "01"
                                  ? $t("permissionsharefile.viewonly")
                                  : props.item.permission_account === "02"
                                  ? $t("permissionsharefile.download")
                                  : props.item.permission_account === "03"
                                  ? $t("permissionsharefile.upload")
                                  : props.item.permission_account === "04"
                                  ? $t("permissionsharefile.edit")
                                  : props.item.permission_account === "05"
                                  ? $t("permissionsharefile.delete")
                                  : "-"
                              }}
                            </td>
                            <td
                              width="10%"
                              class="text-center"
                              v-if="props.item.file_status === 'M'"
                            ></td>
                            <td width="12%" v-else class="text-center">
                              <v-btn
                                class="elevation-0"
                                fab
                                small
                                @click="
                                  setPermissionFileStar(props.item, 'N', 'st')
                                "
                                v-if="props.item.file_status === 'S'"
                              >
                                <v-icon color="amber">star</v-icon>
                              </v-btn>
                              <v-btn
                                class="elevation-0"
                                fab
                                small
                                @click="
                                  setPermissionFileStar(props.item, 'S', 'st')
                                "
                                v-else
                                text
                              >
                                <v-icon>star_border</v-icon>
                              </v-btn>
                              <v-btn
                                class="elevation-0"
                                fab
                                small
                                outlined
                                @click="test($event, props.item)"
                              >
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                    <br />
                    <v-layout row wrap justify-center>
                      <v-flex xs11 lg11>
                        <v-pagination
                          v-model="page"
                          :length="pageCount"
                          :color="color.theme"
                          v-if="rootfile.length > 0"
                          @input="changepage()"
                        ></v-pagination>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <!-- snackbardowload -->
                <div class="text-center ma-2">
                  <v-snackbar v-model="snackbardowload" bottom color="white">
                    <v-list color="white" style="padding-top: 0px;">
                      <v-toolbar
                        :color="color.theme"
                        dense
                        short
                        :dark="color.darkTheme"
                        flat
                        fab
                      >
                        {{ $t("myinboxPage.dowload.dowloading") }}
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          text
                          @click="snackbardowload = false"
                          fab
                          x-small
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-list-item dense style="height:40px; width:400px;">
                        <v-list-item-content>
                          <v-list-item-title>{{ datetime }}</v-list-item-title>
                        </v-list-item-content>
                        <v-progress-circular
                          :rotate="90"
                          :size="35"
                          :width="3"
                          :value="percentCompleted"
                          color="teal"
                          style="margin-left:25px"
                          >&nbsp; {{ percentCompleted }}</v-progress-circular
                        >
                      </v-list-item>
                    </v-list>
                  </v-snackbar>
                </div>
              </v-container>
              <!-- <previewfile
                :show="openpreviewfile"
                :filedata_1="currentfile"
                :arrayfile="arrayfile"
                :parentfolder="parentfolder"
                :param_preview="param_preview"
                @closepreviewfile="openpreviewfile = false"
                @openpreviewfile="openpreviewfile = true"
                @reload="loadstorage(), loadfolder()"
              ></previewfile> -->
              <previewfile_sharedwithme
                :show="openpreviewfile"
                :filedata_1="currentfile"
                :arrayfile="arrayfile"
                :parentfolder="parentfolder"
                :param_preview="param_preview"
                :fileSharedFeature="business_feature_shared_file"
                @closepreviewfile="(openpreviewfile = false), (business_feature_shared_file = null)"
                @openpreviewfile="openpreviewfile = true"
                @reload="loadstorage(), loadsharefolderpagination(true)"
              ></previewfile_sharedwithme>
              <uploadfiles
                :show="openUploadfiles"
                :parentfolder="parentfolder"
                :departmentid="departmentid"
                @close="loadstorage(), loadsharefolderpagination(true), (openUploadfiles = false)"
                @closeDrag="removeEvent()"
                @openDrag="addEvent()"
              ></uploadfiles>
              <!-- @close="loadstorage(),loadfolder(), openUploadfiles = false" -->
              <!-- <uploadfolder
                :show="openUploadfolder"
                :parentfolder="parentfolder"
                :departmentid="departmentid"
                @close="loadfolder(), (openUploadfolder = false)"
                @closeDrag="removeEvent()"
                @openDrag="addEvent()"
              ></uploadfolder> -->
              <!-- @close="loadfolder(), openUploadfolder = false" -->
              <mainrightclick
                :departmentid="''"
                :show="showMenu"
                :permissionfolder="permission_currentfolder"
                :parentfolder="parentfolder"
                :AreaX="x"
                :AreaY="y"
                @loaddatafrommainright="loadsharefolderpagination(true), (showMenu = false)"
                @closemainonly="showMenu = false"
                @closeDrag="removeEvent()"
                @openDrag="addEvent()"
                @openuploadfolder="openUploadfolder = true"
                @open_folder="fn_open_folder"
              ></mainrightclick>
              <!-- @loaddatafrommainright="loadstorage(),loadfolder(), showMenu = false" -->
              <sharefilerightclick
                :show="showcurrentMenu"
                :AreaX="x"
                :AreaY="y"
                @callupdatestatusfile="setPermissionFileStar"
                @closecurrent="showcurrentMenu = false"
                @reload="loadsharefolderpagination(true), loadstorage()"
                :file="currentfile"
                :arrayfile="rootfile"
                :typesharefile="typesharefile"
              ></sharefilerightclick>
              <!-- @closecurrent="showcurrentMenu = false,loadfolder(), loadstorage()" -->
              <dialogcheckmultidownload
                :show="opendialogcheckmultidownload"
                @closedialog="
                  (opendialogcheckmultidownload = false),
                    (filemultipledownload = [])
                "
                :data="filemultipledownload"
              ></dialogcheckmultidownload>
              <!-- @closecurrent="showcurrentMenu = false,loadfolder(), loadstorage()" -->
              <rightcheckpassword
                :show="opendialog_right"
                :filedata="newdata"
                :key_st="check_btn"
                @closedialog="opendialog_right = false"
                @close_c_star="opendialog_checklog = false"
                @callapi="
                  loadstorage(), loadsharefolderpagination(true), (opendialog_right = false)
                "
                @close_star="setPermissionFileStar(newdata, star_status)"
              />
              <checkpassword
                :show="opendialog_checklog"
                @closedialog="backtopreventfolder()"
                @close_c_star="opendialog_checklog = false"
                @close="opendialog_checklog = false"
                :filedata="newdata"
                :key_st="check_btn"
                @close_star="setPermissionFileStar(newdata, star_status)"
              />
            </v-card-text>
          </v-card>
        </v-card>
      </v-content>
      <!-- <div style="visibility:hidden;" id="dropzone">
        <div id="textnode">
          <v-icon size="200" dark>cloud_upload</v-icon>
          <h3>{{ $t("uploadFile.dropfile") }}</h3>
        </div>
      </div> -->
      <uploadfolder
      :parentfolder="parentfolder"
      :enabled_inputfile="openUploadfolder"
      :departmentid="departmentid"
      @closewithoutload="openUploadfolder = false"
      @closeUpload="(openUploadfolder = false), loadsharefolderpagination(true)"
      ></uploadfolder>
      <dragdropupload
        :removeevent="removeevent"
        :addevent="addevent"
        :departmentid="departmentid"
        @loadfile="loadstorage(), loadfolder(), removeevent = false"
        :parentfolder="parentfolder"
        @snackbarupload="removeevent = true"
        @detailupload="removeevent = true"
      ></dragdropupload>
    </div>
  </v-app>
</template>

<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gb_advanceSearch from "@/globalFunctions/advanceSearch";
import VueCookies from "vue-cookies";
import gb_checkeditonline from "@/globalFunctions/checkinetjv";
// Import Component
// const uploadfolder = () => import("../components/upload/dialog-uploadfolder");
const uploadfolder = () => import("../components/upload/input-uploadfolder");
const uploadfiles = () => import("../components/upload/dialog-uploadfiles");
const sharefilerightclick = () =>
  import("../components/contextmenu/sharefilerightclick");
const mainrightclick = () => import("../components/contextmenu/mainrightclick");
const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
// const previewfile = () => import("../components/optional/dialog-preview");
const previewfile_sharedwithme = () => import("../components/optional/dialog-preview-sharedwithme");
// const dialog_filter = () => import("../components/optional/dialog-filter");
const dragdropupload = () =>
  import("../components/optional/dialog-dragdropupload-chunk");
const dialogcheckmultidownload = () =>
  import("../components/optional/dialog-checkmultidownload");
const checkpassword = () =>
  import("../components/optional/dialog-checkpassword");
const rightcheckpassword = () =>
  import("../components/optional/dialog-rightclickcheckpassword.vue");
const floatingbutton = () => import("../components/optional/dialog-floatingbutton");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  components: {
    checkpassword,
    rightcheckpassword,
    uploadfiles,
    onebox_toolbar,
    mainrightclick,
    VueFullScreenFileDrop,
    uploadfolder,
    sharefilerightclick,
    // previewfile,
    previewfile_sharedwithme,
    // dialog_filter,
    dragdropupload,
    dialogcheckmultidownload,
    floatingbutton,
  },
  data: function() {
    return {
      business_feature_shared_file: null,
      count_data_adv_:0,
      word_searched: "",
      heardertextsearch:false,
      param_preview:"",
      check_btn: "",
      opendialog_checklog: false,
      newdata: {},
      opendialog_right: false,
      show_back: false,
      show_multipledownload: false,
      show_download: false,
      show_clear: false,
      opendialogcheckmultidownload: false,
      arrayfile: [],
      removeevent: false,
      addevent: false,
      departmentid: "",
      get_permission: "",
      permission: "",
      data_doctype: [],
      folderid: "",
      system: "",
      status_aspect_key: "",
      filesLastView: [],
      percentCompleted: 0,
      datetime: "",
      snackbardowload: false,
      fileCount: "",
      countfolder: "",
      countfile: "",
      myDateDownload: new Date().toISOString().slice(0, 10),
      statusmutipledownload: false,
      filemultipledownload: [],
      search: "",
      navigate: [],
      page: 1,
      size: 50,
      listsize: [20, 50, 100],
      listsizemenu: [
        { value:20 },
        { value:50 },
        { value:100 }
      ],
      // typesharefile:'ไฟล์แชร์ร่วมกัน',
      // listtypesharefile:["ไฟล์ที่ซ่อน","ไฟล์แชร์ร่วมกัน"],
      typesharefile: {
        name: "filesharewithme",
        key: "S",
        text: "sharewithme.filesharewithme",
      },
      listtypesharefile: [
        {
          name: "filesharewithme",
          key: "S",
          text: "sharewithme.filesharewithme",
        },
        { name: "hiddenfile", key: "T", text: "sharewithme.hiddenfile" },
      ],
      foldername: "",
      loaddataprogress: true,
      parentfoldername: "",
      parentfolder: this.$route.params.id,
      loadingfile: false,
      openpreviewfile: false,
      openUploadfolder: false,
      openUploadfiles: false,
      showcurrentMenu: false,
      currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: "",
      },
      showMenu: false,
      currentfilename: "",
      x: 0,
      y: 0,
      singleSelect: false,
      selected: [],
      permission_currentfolder: {
        download: "False",
        edit: "False",
        view_only: "True",
        upload: "False",
        delete: "False",
      },
      headers: [
        {
          text: "#",
          align: "center",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "20%",
          sortable: false,
        },
        {
          text: "tablefile.owner",
          align: "left",
          value: "file_owner",
          width: "15%",
          sortable: false,
        },
        {
          text: "tablefile.lastmodified",
          value: "file_lastdtm",
          width: "15%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "12%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.permission",
          value: "permission",
          width: "15%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.option",
          value: "file_status",
          width: "12%",
          align: "center",
          sortable: false,
        },
      ],
      clickSort: false,
      multiSort: false,
      rootfile: [],
      folderSort: [],
      fileSort: [],
      sortdata: [],
      buff: [],
      showPrevMenu: false,
      triggered_rc_fileorfolder: false,
      sorting: {
        sort: [{
            id: 'name',
            text: 'sorting.name',
            icon: 'mdi-file-document'
          }, {
            id: 'cre_dtm',
            text: 'sorting.date_created',
            icon: 'mdi-calendar-multiselect'
          }, {
            id: 'last_dtm',
            text: 'sorting.date_modified',
            icon: 'mdi-timer'
          }, {
            id: 'type',
            text: 'sorting.type',
            icon: 'mdi-text'
          },{
            id: 'size_file',
            text: 'sorting.size_file',
            icon: 'mdi-clipboard'
          }
        ],
        direction: [{
            id: 'ASC',
            text: 'sorting.ascending'
          }, {
            id: 'DESC',
            text: 'sorting.descending'
          }
        ]
      },
      sortAndDirection: {
        sort_by: localStorage.getItem('sort_by') !== null ? localStorage.getItem('sort_by') : "cre_dtm",
        direction: localStorage.getItem('direction_by') !== null ? localStorage.getItem('direction_by') : "ASC",
      },
      sortBy: [],
      sortby: "",
      sortDesc: [],
      checkpage: 0,
      pagefile: 1,
      limitfile: 0
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "feature_onlyoffice"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        // let l = this.rootfile.length;
        // let l = this.fileCount;
        let l = this.countfile + this.countfolder;
        let s = this.size;
        console.log(Math.ceil(l / s));
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootfile.slice(start, start + this.size);
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 24px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    //สีอักษรของ headerpage ของ mobile
    headerPageMobile() {
      return "color:" + this.color.theme + ";" + "font-size: 16px; font-weight: 700;";
    },
    // ขนาดของ chip ที่แสดง headerpage
    headerChipPage() {
      return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;"
    },
    //สีพื้นหลังและขอบของ card ที่แสดงรายการ
    displayMobile() {
      if (this.$vuetify.theme.dark){
        return "background: #464646; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      } else {
        return "background: #FFFFFF; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      }
    },
    // ตัวหนา
    listOnBold() {
      return "font-size: 14px; font-weight: 700; line-height: 24px;";
    },
    // ตัวบาง
    listNoBold() {
      return "font-size: 14px; font-weight: 400; color: #636363; line-height: 24px;";
    },
    // ขอบของ sortby
    roundListSort() {
      return "border-radius: 10px; border: 1px solid #f3f5f7; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);"
    },
    // สีพื้นหลังของ list ที่แสดงรายการไฟล์
    styleDisplayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646;"
      } else {
        return "background: #FFFFFF;"
      }
    },
    // สีพื้นหลังและเส้นขอบล่างของ list item ของแต่ละอัน
    displayMobileForListItem() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border-bottom: 1px solid #F3F5F7;"
      } else {
        return "background: #FFFFFF; border-bottom: 1px solid #F3F5F7;"
      }
    },
    // สีพื้นหลังของ Floating Button (ปุ่มลอยขวาล่าง)
    colorFlotingButton() {
      if (this.$vuetify.theme.dark) {
        return "background: #9A9A9A;"
      } else {
        return "background: linear-gradient(270deg, #176C91 0%, #71B5D8 100%);"
      }
    }
  },
  watch: {
    permission_currentfolder(val) {
      if (val["upload"] == "False" || val === "01" || val === "02") {
        this.removeEvent();
      } else {
        this.addEvent();
      }
    },
    sortAndDirection: {
      async handler(newValue, oldValue) {
        await new Promise((resolve) => {
          // console.log('sortAndDirection',newValue, oldValue);
          localStorage.setItem('sort_by', newValue.sort_by)
          localStorage.setItem('direction_by', newValue.direction)
          this.page = 1;
          const mapSortName = {
            type: "file_type",
            name: "file_name",
            last_dtm: "file_lastdtm",
            size_file: "file_size",
          }
          let _sortBy = mapSortName[newValue.sort_by]
          if (!_sortBy) {
            this.sortBy = []
            this.sortDesc = []
          } else {
            this.sortBy = [_sortBy]
            this.sortDesc = [newValue.direction === 'DESC']
          }
          // console.log('_sortBy',_sortBy)
          // console.log('sortDesc',this.sortDesc)
          resolve()
        });
        this.loadsharefolderpagination(true);
      },
      deep: true
    },
  },
  methods: {
    reloadSortByAndDirection() {
      this.loadstorage();
      this.loadsharefolderpagination(true);
    },
    fn_open_folder(data){
      let folder_data = {
        account_id: this.dataAccountId,
        account_reciever: "",
        account_sender: this.dataAccountId,
        editing_status: "N",
        file_createdtm: data.cre_dtm,
        file_department: "",
        file_icon: [
          "mdi-folder",
          "#ffc107"
        ],
        file_id: data.folder_id,
        file_lastdtm: data.cre_dtm,
        file_linkshare: null,
        file_name: data.folder_name,
        file_owner_eng: "Me",
        file_owner_th: "ฉัน",
        file_permission: {
          "admin_data": "False",
          "delete": "True",
          "download": "True",
          "edit": "True",
          "upload": "True",
          "view_only": "True"
        },
        file_permission_2: "05",
        file_size: "-",
        file_status: "N",
        file_status_share: "N",
        file_status_sharelink: "N",
        file_type: "folder",
        last_update_name:{
          "name_eng": "Me",
          "name_th": "ฉัน"
        },
        starred_id: null,
        status_lock_data: "N",
        type_data: "folder",
        relation_status: "",
        relation_name: "",
        relation_id: "",
        status_check: "N"
      }
      this.gotodirectorysharefile(data.folder_id, folder_data.file_type, folder_data)
    },
    fn_hilightWord (text) {
      var regex = new RegExp(this.word_searched, "gi")
      if(this.$vuetify.theme.dark){
        var response = text.replaceAll(regex, function(str) {
          return "<span style='background-color: #827917'>" + str + "</span>"
        })
      } else {
        var response = text.replaceAll(regex, function(str) {
          return "<mark>" + str + "</mark>"
        })
      }
      return response
      //return text.replaceAll(new RegExp(this.data_search.name,"gi"), `<mark>${this.data_search.name}</mark>`);
    },
    //รับข้อมูลจาก toolbar
    fn_advancesearch(_param , wordSearch) {
      console.log(_param);
      this.count_data_adv_ = _param.data.total;
      this.heardertextsearch = true;
      // this.disablescrolling = true;
      this.rootfile = [];
      this.word_searched = wordSearch
      this.buff = [];
      const datafolder = gb_advanceSearch.setDataCompleteAdvanceSearch_(
        _param,
        this.dataAccountId
      );
      console.log("datafolder", datafolder);
      let count_file = datafolder.filter((item) => {
        return item.file_type === "file";
      });
      let count_folder = datafolder.filter((item) => {
        return item.file_type === "folder";
      });
      this.totaldata = {
        totalall: count_file.length + count_folder.length,
        totalfile: count_file.length,
        totalfolder: count_folder.length,
      };
      this.rootfile = datafolder;
      console.log("this.rootfile1",this.rootfile);
      for (let index = 0; index < this.rootfile.length; index++) {
        const element = this.rootfile[index];
        element.file_thumbnail = "";
        // let typeFile = element.file_name.split(".");
        // let typefile = response.data.data[i].data_type === "folder" ? "folder" : element.file_name.split(".");
        // this.fn_loadthumbnail(
        //   element.file_id,
        //   typeFile[typeFile.length - 1],
        //   element.file_size,
        //   element.status_lock_data
        // );
      }
      this.buff = datafolder;
      this.filemultipledownload = [];
    },
    removeEvent() {
      this.removeevent = true;
    },
    addEvent() {
      if (this.resolutionScreen >= 500) {
        console.log("open");
        this.removeevent = false;
        console.log(this.removeevent);
      }
    },
    async getdoctype() {
      let payload = {
        // folder_id : this.folderid,
        system: this.system,
      };
      let auth = await gbfGenerate.generateToken();
      console.log("folderid_yourfolder", payload);
      this.axios
        .post( 
          process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/file_get_all_doctype",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log(response);
            var i;
            for (i = 0; i < response.data.result.length; i++) {
              this.data_doctype.push(response.data.result[i]);
            }
            console.log("this.data_doctype----", this.data_doctype);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "error",
          });
        });
    },
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${year}${month}${day}`;
    },
    async loadfilterdata(data) {
      console.log("datadatadata", data);
      this.rootfile = [];
      this.buff = [];
      var payload = {
        user_id: this.dataUsername,
        department_id: "",
        account_id: this.dataAccountId,
        system: "",
        business_id: this.dataAccountActive.business_info.business_id,
        folder_id: this.folderid,
        filename: data.filename,
        doc_type: data.documenttype,
        doc_no_start: data.documentid,
        doc_no_end: data.documentidto,
        doc_date_start: this.formatDate(data.fromdatedocument),
        doc_date_end: this.formatDate(data.todatedocument),
        cre_dtm_start: this.formatDate(data.fromdateupload),
        cre_dtm_end: this.formatDate(data.todateupload),
        // folder_name: data.foldername,
        taxid: data.buyertaxid,
        branch_id: data.buyerbranchid,
        // customer_box: this._customer_box,
        cust_id: data.customer_id,
        customer_name: data.customername,
      };
      console.log(payload);
      this.loaddataprogress = true;
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/v3/search_file_system_by_filter",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            console.log("filterloaddata", response);
            var i;
            for (i = 0; i < response.data.result.length; i++) {
              console.log(response.data.result[i]);
              if (response.data.result[i]["type"] === "folder") {
                let datafolder = {};
                datafolder["account_reciever"] = "";
                datafolder["account_sender"] = this.dataAccountId;
                datafolder["account_id"] =
                  response.data.result[i]["account_id"];
                // datafolder["businees_id"] = response.data.result[i]["businees_id"];
                datafolder["file_id"] = response.data.result[i]["folder_id"];
                datafolder["file_name"] =
                  response.data.result[i]["folder_name"];
                datafolder["file_size"] = "-";
                datafolder["file_lastdtm"] =
                  response.data.result[i]["cre_dtm"] || "-";
                datafolder["file_date"] = "";
                datafolder["file_linkshare"] = response.data.result[i]["link"];
                datafolder["file_status_share"] =
                  response.data.result[i]["status_share"];
                datafolder["file_status_sharelink"] =
                  response.data.result[i]["status_share_link"];
                datafolder["file_status"] =
                  response.data.result[i]["folder_status"];
                datafolder["file_owner_eng"] =
                  response.data.result[i]["name_eng"];
                datafolder["file_owner_th"] =
                  response.data.result[i]["name_th"];
                datafolder["file_type"] = "folder";
                datafolder["file_department"] = "";
                datafolder["file_icon"] = ["folder", "#ffc107"];
                datafolder["file_parent_folder_id"] =
                  response.data.result[i]["parent_folder_id"];
                datafolder["file_permission"] =
                  response.data.result[i]["permission_account"];
                datafolder["file_permission_2"] = this.permission;
                datafolder["permission_account"] = this.permission;
                datafolder["status_lock_data"] =
                  response.data.result[i].status_lock_folder === null
                    ? response.data.result[i].status_lock_file
                    : response.data.result[i].status_lock_folder;
                datafolder["file_permission_department_setting"] =
                  response.data.result[i]["permission_department_setting"];
                datafolder["file_permission_setting"] =
                  response.data.result[i]["permission_setting"];
                datafolder["system"] = response.data.result[i]["system"];
                datafolder["status_check"] = response.data.result[i]["status"];
                datafolder["relation_status"] =
                  response.data.result[i]["relation_status"];
                datafolder["relation_name"] =
                  response.data.result[i]["relation_name"];
                datafolder["relation_id"] =
                  response.data.result[i]["relation_id"];

                this.rootfile.push(datafolder);
                this.buff.push(datafolder);
              } else {
                let typefile = response.data.result[i]["filename"].split(".");
                let datafolder = {};
                datafolder["account_reciever"] = "";
                datafolder["account_sender"] = this.dataAccountId;
                datafolder["account_id"] =
                  response.data.result[i]["account_id"];
                datafolder["businees_id"] =
                  response.data.result[i]["businees_id"];
                datafolder["file_id"] = response.data.result[i]["id"];
                datafolder["file_name"] = response.data.result[i]["filename"];
                datafolder["file_size"] = response.data.result[i]["size_file"];
                datafolder["file_lastdtm"] =
                  response.data.result[i]["last_dtm"] || "-";
                datafolder["file_date"] = "";
                datafolder["file_linkshare"] = response.data.result[i]["link"];
                datafolder["file_status_share"] =
                  response.data.result[i]["status_share"];
                datafolder["file_status_sharelink"] =
                  response.data.result[i]["status_share_link"];
                datafolder["file_owner_eng"] =
                  response.data.result[i]["name_eng"];
                datafolder["file_owner_th"] =
                  response.data.result[i]["name_th"];
                datafolder["file_type"] = typefile[typefile.length - 1];
                datafolder["file_department"] = "";
                datafolder["file_icon"] = this.seticon(
                  typefile[typefile.length - 1]
                );
                datafolder["file_parent_folder_id"] =
                  response.data.result[i]["folder_id"];
                datafolder["file_status"] =
                  response.data.result[i]["status_file"];
                datafolder["file_permission"] =
                  response.data.result[i]["permission_account"];
                datafolder["file_permission_2"] = this.permission;
                datafolder["permission_account"] = this.permission;
                datafolder["status_lock_data"] =
                  response.data.result[i].status_lock_folder === null
                    ? response.data.result[i].status_lock_file
                    : response.data.result[i].status_lock_folder;
                datafolder["file_permission_department"] =
                  response.data.result[i]["permission_department"];
                datafolder["file_permission_department_setting"] =
                  response.data.result[i]["permission_department_setting"];
                datafolder["file_permission_setting"] =
                  response.data.result[i]["permission_setting"];
                datafolder["relation_id"] =
                  response.data.result[i]["relation_id"];
                datafolder["relation_name"] =
                  response.data.result[i]["relation_name"];
                datafolder["relation_status"] =
                  response.data.result[i]["relation_status"];
                datafolder["status_check"] = response.data.result[i]["status"];

                this.rootfile.push(datafolder);
                this.buff.push(datafolder);
              }
            }
            // this.loadfilterfiles(data);
            console.log("rootfilefolder", this.rootfile);
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            this.countfolder = response.data.result.length;
            this.loaddataprogress = false;

            for(let y = 0; y < this.rootfile.length; y++){
              if(this.rootfile[y].file_owner_th === '' || this.rootfile[y].file_owner_eng === ''){
                await this.get_name_owner(this.rootfile[y])
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    // loadfilterfiles(data) {
    //   console.log("dataของfile",data);
    //   // if (data.recivertype === "มี") {
    //   //   this._customer_box = "Y";
    //   // } else if (data.recivertype === "ไม่มี") {
    //   //   this._customer_box = "N";
    //   // } else {
    //   //   this._customer_box = "";
    //   // }
    //   var payload = {
    //     user_id:this.dataUsername,
    //     department_id:"",
    //     account_id: this.dataAccountId,
    //     system: "",
    //     business_id: this.dataAccountActive.business_info.business_id,
    //     folder_id: this.folderid,
    //     filename: data.filename,
    //     doc_type: data.documenttype,
    //     doc_no_start: data.documentid,
    //     doc_no_end: data.documentidto,
    //     doc_date_start: this.formatDate(data.fromdatedocument),
    //     doc_date_end: this.formatDate(data.todatedocument),
    //     cre_dtm_start: this.formatDate(data.fromdateupload),
    //     cre_dtm_end: this.formatDate(data.todateupload),
    //     // folder_name: data.foldername,
    //     taxid: data.buyertaxid,
    //     branch_id: data.buyerbranchid,
    //     // customer_box: this._customer_box,
    //     cust_id: data.customer_id,
    //     customer_name: data.customername,
    //     limit:"",
    //     offset:""
    //   };
    //   console.log("file",payload);
    //   this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API +
    //         "/api/search_file_in_folder_system_by_filter",
    //       payload
    //     )
    //     .then((response) => {
    //       console.log("ไม่ok",response);
    //       if (response.data.status === "OK") {
    //         console.log("filterfileee", response);
    //         var i;
    //         for (i = 0; i < response.data.result.length; i++) {
    //           let typefile = response.data.result[i]["filename"].split(".");
    //           let datafolder = {};
    //           datafolder["account_reciever"] = "";
    //           datafolder["account_sender"] = this.dataAccountId;
    //           datafolder["account_id"] = response.data.result[i]["account_id"];
    //           datafolder["businees_id"] = response.data.result[i]["businees_id"];
    //           datafolder["file_id"] = response.data.result[i]["id"];
    //           datafolder["file_name"] = response.data.result[i]["filename"];
    //            datafolder["file_size"] = response.data.result[i]["size_file"];
    //           datafolder["file_lastdtm"] = response.data.result[i]["last_dtm"] || "-";
    //           datafolder["file_date"] = "";
    //           datafolder["file_linkshare"] = response.data.result[i]["link"];
    //           datafolder["file_status_share"] = response.data.result[i]["status_share"];
    //           datafolder["file_status_sharelink"] = response.data.result[i]["status_share_link"];
    //           datafolder["file_owner_eng"] = response.data.result[i]["name_eng"];
    //           datafolder["file_owner_th"] = response.data.result[i]["name_th"];
    //           datafolder["file_type"] = typefile[typefile.length - 1];
    //           datafolder["file_department"] = "";
    //           datafolder["file_icon"] = this.seticon(typefile[typefile.length - 1]);
    //           datafolder["file_parent_folder_id"] = response.data.result[i]["folder_id"];
    //           datafolder["file_status"] = response.data.result[i]["status_file"];
    //           datafolder["file_permission"] = response.data.result[i]["permission_account"];
    //           datafolder["permission_account"] = this.permission;
    //           datafolder["file_permission_department"] = response.data.result[i]["permission_department"];
    //           datafolder["file_permission_department_setting"] = response.data.result[i]["permission_department_setting"];
    //           datafolder["file_permission_setting"] = response.data.result[i]["permission_setting"];
    //           datafolder["relation_id"]= response.data.result[i]["relation_id"];
    //           datafolder["relation_name"]= response.data.result[i]["relation_name"];
    //           datafolder["relation_status"]= response.data.result[i]["relation_status"];
    //           datafolder["status_check"] = response.data.result[i]["status"];

    //           this.rootfile.push(datafolder);
    //           this.buff.push(datafolder);
    //         }
    //         console.log("rootfilefile", this.rootfile);
    //         this.loaddataprogress = false;

    //           if (this.clickSort === true) {
    //             this.rootfile.push(this.sortFile(this.rootfile));
    //           }
    //           this.countfile = response.data.result.length;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.loaddataprogress = false;
    //       Toast.fire({
    //         icon: "error",
    //         // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
    //         title: this.$t("toast.cannotconnectonebox"),
    //       });
    //     });
    // },
    checkSort() {
      // this.loadfolder();
      this.rootfile = this.buff;
      this.clickSort = false;
      this.multiSort = false;
    },
    // sortFile(parameter) {
    //   this.folderSort = [];
    //   this.fileSort = [];
    //   this.sortdata = [];
    //   this.rootfile = [];
    //   let i, j;
    //   for (i = 0; i < parameter.length; i++) {
    //     if (parameter[i].file_type === "folder") {
    //       this.folderSort.push(parameter[i]);
    //     } else this.fileSort.push(parameter[i]);
    //   }

    //   if (this.sortby === "name") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_name.localeCompare(b.file_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_name.localeCompare(b.file_name);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_name.localeCompare(a.file_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_name.localeCompare(a.file_name);
    //       });
    //     }
    //   } else if (this.sortby === "date") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_lastdtm.localeCompare(b.file_lastdtm);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_lastdtm.localeCompare(b.file_lastdtm);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_lastdtm.localeCompare(a.file_lastdtm);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_lastdtm.localeCompare(a.file_lastdtm);
    //       });
    //     }
    //   } else if (this.sortby === "size") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_size - a.file_size;
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_size - a.file_size;
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_size - b.file_size;
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_size - b.file_size;
    //       });
    //     }
    //   } else if (this.sortby === "owner") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_owner_eng.localeCompare(b.file_owner_eng);
    //       });
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_owner_th.localeCompare(b.file_owner_th);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_owner_eng.localeCompare(b.file_owner_eng);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_owner_th.localeCompare(b.file_owner_th);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_owner_eng.localeCompare(a.file_owner_eng);
    //       });
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_owner_th.localeCompare(a.file_owner_th);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_owner_eng.localeCompare(a.file_owner_eng);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_owner_th.localeCompare(a.file_owner_th);
    //       });
    //     }
    //   }
    //   this.sortdata.push(this.folderSort);
    //   this.sortdata.push(this.fileSort);
    //   for (i = 0; i < this.sortdata.length; i++) {
    //     for (j = 0; j < this.sortdata[i].length; j++) {
    //       this.rootfile.push(this.sortdata[i][j]);
    //     }
    //   }
    //   return this.rootfile;
    // },
    clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    multipledownload() {
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        console.log("ไฟล์ที่เลือก", this.filemultipledownload);
        this.opendialogcheckmultidownload = true;
      }
      // this.percentCompleted = 0;
      // var d = new Date();
      // this.datetime =
      //   d.toISOString().slice(0, 10) +
      //   " " +
      //   "at" +
      //   " " +
      //   this.zeroPadding(d.getHours(), 2) +
      //   "-" +
      //   this.zeroPadding(d.getMinutes(), 2) +
      //   "-" +
      //   this.zeroPadding(d.getSeconds(), 2);
      // if (this.filemultipledownload.length < 1) {
      //   Toast.fire({
      //     type: "warning",
      //     title: this.$t("myinboxPage.msg.nofile"),
      //   });
      // } else {
      //   let filedata = [];
      //   for (let i = 0; i < this.filemultipledownload.length; i++) {
      //     if (this.filemultipledownload[i].file_type === "folder") {
      //       filedata.push({
      //         id: this.filemultipledownload[i]["file_id"],
      //         type: this.filemultipledownload[i]["file_type"],
      //       });
      //     } else {
      //       filedata.push({
      //         id: this.filemultipledownload[i]["file_id"],
      //         type: "file",
      //       });
      //     }
      //   }
      //   let auth = await gbfGenerate.generateToken();
      //   let payload = {
      //     list: filedata,
      //     account_id: this.dataAccountId,
      //   };
      //   this.snackbardowload = true;
      //   this.axios
      //     .post(
      //       process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
      //         "/api/download_multiples_all",
      //       payload,
      //       {
      //         headers: { Authorization: auth.code },
      //         onDownloadProgress: (progressEvent) => {
      //           let progresspercent = parseInt(
      //             Math.round((progressEvent.loaded / progressEvent.total) * 100)
      //           );
      //           // console.log(progresspercent);
      //           if (progresspercent >= 95) {
      //             this.percentCompleted = 95;
      //           } else {
      //             this.percentCompleted = progresspercent;
      //           }
      //         },
      //         withCredentials: false,
      //         responseType: "arraybuffer",
      //         headers: { Authorization: auth.code },
      //       }
      //     )
      //     .then((response) => {
      //       this.statusmutipledownload = false;
      //       console.log(response);
      //       if (response.statusText === "OK") {
      //         this.percentCompleted = 100;
      //       }
      //       //     const url = window.URL.createObjectURL(new Blob([response.data]));
      //       //     const link = document.createElement("a");
      //       //     link.href = url;
      //       //     link.setAttribute("download",datetime+".zip");
      //       //     document.body.appendChild(link);
      //       //     link.click();
      //       const blob = new Blob([response.data]);
      //       const content = response.headers["content-type"];
      //       saveAs(blob, this.datetime + ".zip");
      //       this.clearmultipledownload();
      //     })
      //     .catch((error) => {
      //       Toast.fire({
      //         icon: "error",
      //         title: this.$t("myinboxPage.msg.apierror"),
      //       });
      //       console.log(error);
      //     });
      // }
    },
    // multipledownload() {
    //   var d = new Date();
    //   let datetime =d.toISOString().slice(0,10)+" "+"at"+" "+this.zeroPadding(d.getHours(),2)+"-"+this.zeroPadding(d.getMinutes(),2)+"-"+this.zeroPadding(d.getSeconds(),2);
    //   if (this.filemultipledownload.length < 1) {
    //     Toast.fire({
    //       type: "warning",
    //       title: this.$t("myinboxPage.msg.nofile")
    //     });
    //   } else {
    //     this.$store.dispatch("show_loading", true);
    //     let filedata = [];
    //     for (let i = 0; i < this.filemultipledownload.length; i++) {
    //       filedata.push(this.filemultipledownload[i]["file_id"]);
    //     }

    //     let payload = {
    //       list_file: filedata,
    //       account_id: this.dataAccountId
    //     };

    //     this.axios
    //       .post(
    //         process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
    //           "/api/multiple_downloads_file",
    //         payload,
    //         {
    //           withCredentials: false,
    //           responseType: "blob"
    //         }
    //       )
    //       .then(response => {
    //         this.statusmutipledownload = false;
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.setAttribute("download",datetime+".zip");
    //         document.body.appendChild(link);
    //         link.click();
    //         this.$store.dispatch("show_loading", false);
    //         this.clearmultipledownload();
    //       })
    //       .catch(error => {
    //         this.$store.dispatch("show_loading", false);
    //         Toast.fire({
    //           icon: "error",
    //           title: this.$t("myinboxPage.msg.apierror")
    //         });
    //         console.log(error);
    //       });
    //   }
    // },
    filteredItems(search) {
      this.search = search;
    },
    formatdatetime(_datetime) {
      if (
        _datetime === "" ||
        _datetime === "-" ||
        _datetime === undefined ||
        _datetime === null
      ) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute 
          // +
          // ":" +
          // second
        );
      }
    },
    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },
    backtoroot() {
      this.$router.replace("/sharedwithme");
    },
    // setPermissionFileStar(parameter, status) {
    //   console.log("para",parameter);
    //   const Toast = Swal.mixin({
    //     toast: true,
    //     position: "top-end",
    //     showConfirmButton: false,
    //     timer: 3000
    //   });
    //   let api;
    //   let payload;
    //   let msgres;
    //   if (parameter.file_type === "folder") {
    //     payload = {
    //       folder_id: parameter.file_id,
    //       folder_name: "",
    //       status_folder: status,
    //       move_to_id: "",
    //       account_id: parameter.account_sender
    //       // user_id: this.dataUsername
    //     };
    //     api = "/api/v2/update_folder_id";
    //   } else {
    //     payload = {
    //       // user_id: this.dataUsername,
    //       account_id: parameter.account_sender,
    //       file_id: parameter.file_id,
    //       filename: "",
    //       folder_id: "",
    //       status_file: status
    //     };
    //     api = "/api/v2/update_file_id";
    //   }
    //   this.axios
    //     .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload)
    //     .then(response => {
    //       console.log(response);
    //       if (response.data.status === "OK") {
    //         if (status === "S") {
    //           msgres = "ติดดาว " + parameter.file_name + " สำเร็จ";
    //         } else if (status === "T") {
    //           msgres = "นำ" + parameter.file_name + " ออกสำเร็จ";
    //         } else {
    //           msgres = "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ";
    //         }

    //         Toast.fire({
    //           icon: "success",
    //           title: msgres
    //         });
    //         // this.$emit('closecurrent')
    //         this.loadfolder();
    //       } else {
    //         Toast.fire({
    //           icon: "error",
    //           title: response.data.errorMessage
    //         });
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถติดดาว " + parameter.file_name + " ได้"
    //       });
    //     });
    // },
    async setPermissionFileStar(parameter, status, key) {
      console.log("para", parameter);
      if (status === "T") {
          // this.opensdialogcheckdeleterightclick = true;
          this.deletenewfunction(parameter);
        } else {
        if (parameter.status_lock_data === "Y" && key === "st") {
          console.log("setPermissionFileStar", parameter, status);
          if (parameter.type_data === "folder") {
            this.opendialog_checklog = true;
            console.log(parameter);
            this.newdata = parameter;
            this.star_status = status;
            this.check_btn = key;
          } else {
            this.check_btn = key;
            this.opendialog_right = true;
            this.newdata = parameter;
            this.star_status = status;
          }
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
          let api;
          let payload;
          let msgres;
          if (status === "S") {
            payload = {
              starred_id: [parameter.starred_id],
              data_id: [parameter.file_id],
              account_id: parameter.account_sender,
              process: "insert",
            };
            api = "/api/multiple_star";
          } else {
            payload = {
              starred_id: [parameter.starred_id],
              data_id: [parameter.file_id],
              account_id: parameter.account_sender,
              process: "delete",
            };
            api = "/api/multiple_star";
          }
          // if (parameter.file_type === "folder") {
          //   payload = {
          //     folder_id: parameter.file_id,
          //     folder_name: "",
          //     status_folder: status,
          //     move_to_id: "",
          //     account_id: parameter.account_sender,
          //     // user_id: this.dataUsername
          //   };
          //   api = "/api/v2/update_folder_id";
          //   // payload = {
          //   //     id: parameter.file_id,
          //   //     account_id: parameter.account_sender,
          //   //     status_folder: status,
          //   //     permission:parameter.permission_account
          //   //   };
          //   //   api = "/api/update_share_statusfolder_v4";
          // } else {
          //   payload = {
          //     // user_id: this.dataUsername,
          //     account_id: parameter.account_sender,
          //     file_id: parameter.file_id,
          //     filename: "",
          //     folder_id: "",
          //     status_file: status,
          //   };
          //   api = "/api/v2/update_file_id";
          //   //  payload = {
          //   //     id: parameter.file_id,
          //   //     account_id: parameter.account_sender,
          //   //     status_file: status,
          //   //     permission:parameter.permission_account
          //   //   };
          //   //   api = "/api/update_share_statusfile_v4";
          // }
          let auth = await gbfGenerate.generateToken();
          this.axios
            .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload, {
              headers: { Authorization: auth.code },
            })
            .then((response) => {
              // console.log(response);
              if (response.data.status === "OK") {
                for (let i = 0; i < response.data.data.length; i++) {
                  let objIndex = this.rootfile.findIndex((obj) => obj.file_id === response.data.data[i]["data_id"]);
                  if (objIndex !== -1) {
                    console.log(response);
                    console.log(status);

                    if (status === "S") {
                      this.rootfile[objIndex]["file_status"] = 'S';
                      this.rootfile[objIndex]["starred_id"] = response.data.data[i]["starred_id"]; 
                      // "ติดดาว " + parameter.file_name + " สำเร็จ"
                      msgres =
                        this.$t("toast.text.starred") +
                        parameter.file_name +
                        this.$t("toast.text.success");
                    } else if (status === "T") {
                      this.rootfile[objIndex]["file_status"] = 'T';
                      this.rootfile[objIndex]["starred_id"] = response.data.data[i]["starred_id"]; 
                      // "นำ " + parameter.file_name + " ออกสำเร็จ"
                      msgres =
                        this.$t("toast.text.remove") +
                        parameter.file_name +
                        this.$t("toast.text.success");
                    } else {
                      this.rootfile[objIndex]["file_status"] = 'N';
                      this.rootfile[objIndex]["starred_id"] = response.data.data[i]["starred_id"]; 
                      // "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ"
                      msgres =
                        this.$t("toast.text.canclestart") +
                        parameter.file_name +
                        this.$t("toast.text.success");
                    }
                  }
                }
                Toast.fire({
                  icon: "success",
                  title: msgres,
                });
                // this.$emit('closecurrent')
                // this.loadsharefolderpagination(false);
                // this.loadfileandfolder();
              } else {
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                });
              }
            })
            .catch((error) => {
              console.log(error);
              Toast.fire({
                icon: "error",
                // "ไม่สามารถติดดาว " + parameter.file_name + " ได้"
                title:
                  this.$t("toast.text.cannotstar") +
                  parameter.file_name +
                  this.$t("toast.text.textth"),
              });
            });
        }
    }
    },
     //ลบไฟล์ โฟลเดอร์
     async deletenewfunction(parameter) {
      let payload = {
        account_id: parameter.account_sender,
        user_id: this.dataUsername,
        data_id: parameter.file_id,
        data_type: parameter.file_type === "folder" ? "folder" : "file",
        status_data: "T",
        status_share: "Y"
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES +
          "/api/remove_data_to_trash", //"/api/remove_file_folder_to_trash",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("delresponse", response);
            Toast.fire({
              icon: "success",
              title:
                this.$t("toast.text.remove") +
                parameter.file_name +
                " " +
                this.$t("toast.text.totrash"),
            });
            this.loadsharefolderpagination(false);
            this.loadstorage();
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    test(e, filename) {
      e.preventDefault();
      this.sendParentfolder();
      this.showcurrentMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false;
      this.triggered_rc_fileorfolder = true
      this.$nextTick(() => {
        this.currentfile = filename;
        this.showcurrentMenu = true;
        if (this.showMenu === true) {
          this.showcurrentMenu = false;
        }
      });
    },
    gotodirectorysharefile(folderid, type, file) {
      if (type === "folder") {
        // localStorage.setItem("changeStoreUI","true")
        // localStorage.setItem("changeUI","true")
        // this.changeUI = true;
        this.rootfile = [];
        this.buff = [];
        this.$router.replace({ path: "/directorysharefile/" + folderid });
        this.loadsharefolderpagination(true);
        // this.loadfileandfolder();
        this.parentfolder = this.$route.params.id;
      } else {
        // let checkinet_jv = gb_checkeditonline.checkeditonlineinetjv(this.dataAccountActive.taxid)
          // console.log("checkinet_jv",checkinet_jv);
          // if(checkinet_jv === -1){
        // let checkinet_jv = VueCookies.get("feature_onlyoffice")
        // if(checkinet_jv === 'false'){
        let checkinet_jv
        let all_feature_file_shared = file.feature_shared_file
        let findFeatureBusinessIndex = file.feature_shared_file.findIndex((obj) => obj.feature === "only_office" );
        // # ทำแบบอิงตาม feature ต้นทางง ถ้าหาก มี ก็สามารถแกได้ตามสิท แต่ถ้าหากไม่มี ก็ไม่สามารถแก้ไขได้
        if(this.dataAccountActive.type === 'Citizen') {
            try {
              if(all_feature_file_shared[findFeatureBusinessIndex].feature_status === 'Y' ){
                this.business_feature_shared_file = all_feature_file_shared[findFeatureBusinessIndex]
                checkinet_jv = true
              } else {
                this.business_feature_shared_file = undefined
                checkinet_jv = false
              }
            }
            catch (err) {
              checkinet_jv = false;
            }
            
        } else { 
            try {
              if(all_feature_file_shared[findFeatureBusinessIndex].feature_status === 'Y' ){
                this.business_feature_shared_file = all_feature_file_shared[findFeatureBusinessIndex]
                checkinet_jv = true
              } else {
                this.business_feature_shared_file = undefined
                checkinet_jv = false
              }
            }
            catch (err) {
              checkinet_jv = this.feature_onlyoffice;
            }
        }
          if(checkinet_jv === false){
            this.param_preview = "preview";
            this.openpreviewfile = true;
          }else{
            if(
              // (['04', '05', '06'].includes(file.file_permission_2)) &&
              (file.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file.file_type.toLowerCase()) || 
              file.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file.data_type.toLowerCase()))
            ){
              this.param_preview = "editfileonline";
            }else{
              this.param_preview = "preview";
            }
          }
        this.currentfile = file;
        this.arrayfile = this.rootfile;
        // this.openpreviewfile = true;
        if(file.editing_status === 'S'){
          this.openpreviewfile = false;
          Toast.fire({
            icon: "warning",
            title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
          });
        }else if(file.editing_status === 'Y'){
          if(file.last_update_name.name_th === 'ฉัน'){
            this.openpreviewfile = true;
          }else{
            this.openpreviewfile = false;
            Toast.fire({
              icon: "warning",
              title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
            });
          }
        }else{
          this.openpreviewfile = true;
        }
      }
    },
    show(e) {
      e.preventDefault();
      this.sendParentfolder();
      this.showMenu = false;
      if (this.triggered_rc_fileorfolder === true ){
        //do not work this 
        this.showPrevMenu = true
      }
      this.x = e.clientX;
      this.y = e.clientY;
       this.$nextTick(() => {
        if (this.showcurrentMenu === true) {
          if (this.showPrevMenu === true){
            this.showMenu = false
          } else {
            this.showMenu = true
            this.showcurrentMenu = false
          }
        } else {
          this.showMenu = true;
        }
        this.showPrevMenu = false
        this.triggered_rc_fileorfolder = false
      });
    },
    onDrop(files) {
      console.log(files);
    },
    sendParentfolder() {
      if (this.$route.params.id === undefined) {
        this.parentfolder = "";
        this.parentfoldername = "ROOT";
      } else {
        this.parentfolder = this.$route.params.id;
        this.parentfoldername = this.foldername;
      }
      // this.openUploadfolder = true
    },

    // โหลด file & folder new
    async loadfileandfolder() {
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      console.log("limit", cal_limit);
      console.log("offset", cal_offset);
      // CLEAR PARAMETER
      // this.newrootfile = [];
      this.fileCount = 0;
      this.countfile = 0;
      this.countfolder = 0;
      this.buff = [];
      this.rootfile = [];
      // Different form myfile
      this.loaddataprogress = true;

      // Personal
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.$route.params.id,
        status_file: "",
        status_folder: "",
        limit: cal_limit,
        offset: cal_offset,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_MULTLPLES +
            "/api/select_files_and_folder",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            console.log("Response new API(DirectorySH) ", response);
            // this.aspect = response.data.your_folder[0]["aspect"];
            // console.log("aspectsharefile----",this.aspect);
            // this.folderid = response.data.your_folder[0]["folder_id"];
            // console.log("folderid-----",this.folderid);

            // Add on
            // this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            // console.log("data",response.data.folder.length);

            let checknavigate = this.navigate.findIndex(
              (y) => y.text[1] === this.$route.params.id
            );
            if (checknavigate === -1) {
              if (this.dataAccountActive["type"] === "Business") {
                if (
                  response.data.your_folder[0]["folder_id"] ===
                  this.dataAccountActive["business_info"]["my_folder_id"]
                ) {
                  this.navigate.push({
                    text: [
                      this.$store.getters.dataTypeusercurrent(
                        this.$store.state.account_active["type"],
                        this.$t("default")
                      ),
                      response.data.your_folder[0]["folder_id"],
                    ],
                    disabled: false,
                  });
                } else {
                  this.navigate.push({
                    text: [
                      response.data.your_folder[0]["folder_name"],
                      response.data.your_folder[0]["folder_id"],
                    ],
                    disabled: false,
                  });
                }
              } else {
                this.navigate.push({
                  text: [
                    response.data.your_folder[0]["folder_name"],
                    response.data.your_folder[0]["folder_id"],
                  ],
                  disabled: false,
                });
              }
            } else {
              let datanavigate = this.navigate;
              let i;
              this.navigate = [];
              for (i = 0; i <= checknavigate; i++) {
                this.navigate.push(datanavigate[i]);
              }
            }
            this.foldername = response.data.your_folder[0]["folder_name"];

            // ใช้ของเก่าก่อน
            this.fileCount = response.data.count;
            this.countfile = response.data.count_file;
            this.countfolder = response.data.count_folder;
            // Last view
            for (let i = 0; i < response.data.data_last_view.length; i++) {
              let file_lastview = [];
              let typefile = response.data.data_last_view[i].name.split(".");
              let type = response.data.data_last_view[i].types;

              file_lastview["account_reciever"] = "";
              file_lastview["account_sender"] = this.dataAccountId;
              file_lastview["account_id"] =
                response.data.data_last_view[i].account_id;
              file_lastview["countnum"] =
                response.data.data_last_view[i].countnum;
              file_lastview["file_createdtm"] =
                response.data.data_last_view[i].cre_dtm;
              file_lastview["folder_id"] =
                response.data.data_last_view[i].folder_id;
              file_lastview["file_id"] = response.data.data_last_view[i].id;
              file_lastview["file_lastdtm"] =
                response.data.data_last_view[i].last_dtm;
              file_lastview["file_linkshare"] =
                response.data.data_last_view[i].link;
              file_lastview["file_name"] = response.data.data_last_view[i].name;
              file_lastview["file_owner_eng"] =
                response.data.data_last_view[i].name_eng;
              file_lastview["file_owner_th"] =
                response.data.data_last_view[i].name_th;
              file_lastview["file_permission"] =
                response.data.data_last_view[i].permission_account;
              file_lastview["file_permission_2"] = this.permission;
              file_lastview["status_lock_data"] =
                response.data.data_last_view[i].status_lock_folder === null
                  ? response.data.data_last_view[i].status_lock_file
                  : response.data.data_last_view[i].status_lock_folder;
              file_lastview["permission_department_setting"] =
                response.data.data_last_view[i].permission_setting;
              file_lastview["permission_role_setting"] =
                response.data.data_last_view[i].permission_role_setting;
              file_lastview["priority"] =
                response.data.data_last_view[i].priority;
              file_lastview["file_size"] = response.data.data_last_view[i].size;
              file_lastview["file_status"] =
                response.data.data_last_view[i].status;
              file_lastview["file_status_share"] =
                response.data.data_last_view[i].status_share;
              file_lastview["file_status_sharelink"] =
                response.data.data_last_view[i].status_share_link;
              file_lastview["system"] = response.data.data_last_view[i].system;
              file_lastview["type"] = response.data.data_last_view[i].types;
              file_lastview["file_icon"] = this.seticonNew(
                type,
                typefile[typefile.length - 1]
              );
              file_lastview["file_type"] =
                type == "folder" ? "folder" : typefile[typefile.length - 1];
              file_lastview["user_id"] =
                response.data.data_last_view[i].user_id;
              file_lastview["starred_id"] = response.data.data[i].starred_id;

              this.filesLastView.push(file_lastview);
            }
            // File and Folder
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].name.split(".");
              let type = response.data.data[i].types;

              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] =
                response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] =
                response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] =
                response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] =
                response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] =
                response.data.data[i].name_th;
              dataFileAndFolder["file_permission"] =
                response.data.data[i].permission_account;
              dataFileAndFolder["file_permission_2"] = this.permission;
              dataFileAndFolder["status_lock_data"] =
                response.data.data[i].status_lock_folder === null
                  ? response.data.data[i].status_lock_file
                  : response.data.data[i].status_lock_folder;
              // dataFileAndFolder["file_permission"] =
              //   response.data.data[i].permission_account;
              dataFileAndFolder["permission_department_setting"] =
                response.data.data[i].permission_setting;
              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] =
                response.data.data[i].status_share;
              dataFileAndFolder["file_status_sharelink"] =
                response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].types;
              dataFileAndFolder["file_icon"] = this.seticonNew(
                type,
                typefile[typefile.length - 1]
              );
              dataFileAndFolder["file_type"] =
                type == "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["starred_id"] =
                response.data.data[i].starred_id;

              this.rootfile.push(dataFileAndFolder);
            }
            console.log("Newrootfile(DirectorySH)", this.rootfile);
            for(let y = 0; y < this.rootfile.length; y++){
              if(this.rootfile[y].file_owner_th === '' || this.rootfile[y].file_owner_eng === ''){
                await this.get_name_owner(this.rootfile[y])
              }
            }
          }
        })
        .catch((error) => {
          console.log("New Loaddata Error:", error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
      this.loaddataprogress = false;
    },
        //ดึงข้อมูลเจ้าของไฟล์ที่ไม่ใช่ของฉัน
    async get_name_owner(listrootfile){
      console.log("listrootfile",listrootfile);
      let payload = {
        data_id : listrootfile.file_id ,
        data_type : listrootfile.file_type === 'folder' ? 'folder' : 'file',
        type_search : "creator"
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth",auth);
      console.log("auth.code",auth.code);
      await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v1/get/data/creator_name", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            listrootfile.file_owner_eng = response.data.name.name_eng
            listrootfile.file_owner_th = response.data.name.name_th
          } 
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title:"ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    // ตั้ง icon file/folder
    seticonNew(type, parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (type == "folder") {
        dataicon = ["folder", "#ffc107"];
      } else {
        if (parameter === "xlsx" || parameter === "xls" || parameter === "xlsb") {
          dataicon = ["mdi-file-excel-outline", "green"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          dataicon = ["mdi-file-powerpoint-outline", "orange"];
        } else if (parameter === "docx" || parameter === "doc") {
          dataicon = ["mdi-file-word-outline", "primary"];
        } else if (parameter === "html") {
          dataicon = ["mdi-language-html5", "#FF603F"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box-outline", "blue-grey"];
        } else if (parameter === "pdf") {
          dataicon = ["mdi-file-pdf-outline", "red"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code-outline", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text-outline", "blue-grey"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png"
        ) {
          dataicon = ["mdi-file-image-outline", "blue-grey"];
        } else if (parameter === "shortcut") {
          dataicon = ["mdi-file-link-outline", "pink"];
        } else {
          dataicon = ["mdi-file-outline", "black"];
        }
      }

      return dataicon;
    },

    async loadfolder() {
      this.heardertextsearch = false;
      this.get_permission = sessionStorage.getItem("permission");
      // console.log("-----------",this.get_permission);
      this.permission = JSON.parse(this.get_permission);
      // console.log("jsonparse",this.permission);

      // alert("Old function");
      var payload;
      this.loaddataprogress = true;
      this.rootfile = [];
      this.buff = [];
      // if(this.typesharefile === 'ไฟล์แชร์ร่วมกัน'){
      //   var payload = {
      //         user_id: this.dataUsername,
      //         account_id: this.dataAccountId,
      //         folder_id: this.$route.params.id,
      //         status: "",
      //   };
      // }else if(this.typesharefile === 'ไฟล์ที่ซ่อน'){
      //   var payload = {
      //         user_id: this.dataUsername,
      //         account_id: this.dataAccountId,
      //         folder_id: this.$route.params.id,
      //         status: "T",
      //   };
      // }
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      if (this.typesharefile.name === "filesharewithme") {
        var payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.$route.params.id,
          status: "",
          limit: cal_limit,
          offset: cal_offset,
          direction: "ASC",
          sort_by: "cre_dtm", 
        };
      } else if (this.typesharefile.name === "hiddenfile") {
        var payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.$route.params.id,
          status: "T",
          limit: cal_limit,
          offset: cal_offset,
          direction: "ASC",
          sort_by: "cre_dtm", 
        };
      }

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_SHARE_FOLDER +
            "/api/search_shared_folder",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            if (response.data.your_folder[0]["status_lock_folder"] === "Y") {
              this.opendialog_checklog = true;
              let folderdata = {};
              folderdata["file_id"] = response.data.your_folder[0]["folder_id"];
              // folderdata["file_type"] = response.data.your_folder[0]["data_type"];
              // folderdata["full_path_id"] = response.data.your_folder[0]["full_path_id"];
              this.newdata = folderdata;
              //console.log(folderdata);
            }
            console.log("rrrr", response);
            let checknavigate = this.navigate.findIndex(
              (y) => y.text[1] === this.$route.params.id
            );
            if (checknavigate === -1) {
              if (this.dataAccountActive["type"] === "Business") {
                if (
                  response.data.your_folder[0]["folder_id"] ===
                  this.dataAccountActive["business_info"]["my_folder_id"]
                ) {
                  this.navigate.push({
                    text: [
                      this.$store.getters.dataTypeusercurrent(
                        this.$store.state.account_active["type"],
                        this.$t("default")
                      ),
                      response.data.your_folder[0]["folder_id"],
                    ],
                    disabled: false,
                  });
                } else {
                  this.navigate.push({
                    text: [
                      response.data.your_folder[0]["folder_name"],
                      response.data.your_folder[0]["folder_id"],
                    ],
                    disabled: false,
                  });
                }
              } else {
                this.navigate.push({
                  text: [
                    response.data.your_folder[0]["folder_name"],
                    response.data.your_folder[0]["folder_id"],
                  ],
                  disabled: false,
                });
              }
            } else {
              let datanavigate = this.navigate;
              let i;
              this.navigate = [];
              for (i = 0; i <= checknavigate; i++) {
                this.navigate.push(datanavigate[i]);
              }
            }
            this.foldername = response.data.your_folder[0]["folder_name"];
            this.system = response.data.your_folder[0]["system"];
            this.status_aspect_key =
              response.data.your_folder[0]["status_aspect_key"];
            this.folderid = response.data.your_folder[0]["folder_id"];
            this.departmentid = "";
            this.permission_currentfolder = this.permission;
            if (
              response.data.your_folder[0]["system"] !== null &&
              response.data.your_folder[0]["status_aspect_key"] === "Y"
            ) {
              localStorage.setItem("changeStoreUI", "false");
              localStorage.setItem("changeUI", "false");
              this.changeUI = false;
              this.getdoctype();
            }
            // console.log("folderid-----",this.folderid);
            // this.rootfile = response.data.folder
            // if (response.data.folder.length) {
            //   this.page += 1;
            //   this.rootfile.push(...response.data.folder);
            //   $state.loaded();
            // } else {
            //   $state.complete();
            // }
            var i;
            for (i = 0; i < response.data.folder.length; i++) {
              let datafolder = {};
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              // datafolder["business_id_reciever"] = response.data.folder[i]["business_id_reciever"];
              datafolder["file_id"] = response.data.folder[i]["folder_id"];
              datafolder["file_name"] = response.data.folder[i]["folder_name"];
              datafolder["file_size"] = "-";
              datafolder["file_lastdtm"] =
                response.data.folder[i]["cre_dtm"] || "-";
              datafolder["file_date"] = "";
              datafolder["file_linkshare"] = response.data.folder[i]["link"];
              datafolder["file_status_share"] =
                response.data.folder[i]["status_share"];
              datafolder["file_status_sharelink"] =
                response.data.folder[i]["status_share_link"];
              datafolder["file_status"] =
                response.data.folder[i]["folder_status"];
              datafolder["file_owner_eng"] =
                response.data.folder[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.folder[i]["name_th"];
              datafolder["file_type"] = "folder";
              datafolder["file_department"] = "";
              datafolder["file_permission"] = "";
              datafolder["file_permission_2"] = this.permission;
              datafolder["permission_account"] = this.permission;
              datafolder["status_lock_data"] =
                response.data.folder[i].status_lock_folder === null
                  ? response.data.folder[i].status_lock_file
                  : response.data.folder[i].status_lock_folder;
              datafolder["file_icon"] = ["folder", "#ffc107"];
              datafolder["relation_status"] =
                response.data.folder[i]["relation_status"];
              datafolder["relation_name"] =
                response.data.folder[i]["relation_name"];
              datafolder["relation_id"] =
                response.data.folder[i]["relation_id"];
              datafolder["status_check"] = response.data.folder[i]["status"];
              datafolder["starred_id"] = response.data.folder[i]["starred_id"];
              datafolder["type_data"] = "folder";
              datafolder["editing_status"] = "";
              datafolder["last_update_name"] = response.data.folder[i]["last_update_name"];
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
            }
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            if (this.system !== null && this.status_aspect_key === "Y") {
              this.getdoctype();
            }
            this.loadfiles(this.$route.params.id, "", this.permission);
            this.countfolder = response.data.folder.length;
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้",
          });
        });
    },
    async loadfiles(folder, status) {
      console.log("logfolder", folder);

      let payload = {};
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      // if(this.typesharefile === 'ไฟล์แชร์ร่วมกัน'){
      //   payload = {
      //     user_id: this.dataUsername,
      //     account_id: this.dataAccountId,
      //     folder_id: folder,
      //     status: status,
      //     status: "",
      //   };
      // }else if(this.typesharefile === 'ไฟล์ที่ซ่อน'){
      //   payload = {
      //   user_id: this.dataUsername,
      //   account_id: this.dataAccountId,
      //   folder_id: folder,
      //   status: status,
      //   status: "T",
      //   }
      // }
      if (this.typesharefile.name === "filesharewithme") {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: folder,
          status: status,
          status: "",
          limit: cal_limit,
          offset: cal_offset,
          direction: "ASC",
          sort_by: "cre_dtm", 
        };
      } else if (this.typesharefile.name === "hiddenfile") {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: folder,
          status: status,
          status: "T",
          limit: cal_limit,
          offset: cal_offset,
          direction: "ASC",
          sort_by: "cre_dtm", 
        };
      }
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      // if (this.dataAccountActive["type"] === "Business") {
      //   let checkPersonalBusiness = this.navigate.findIndex(
      //     r =>
      //       r.text[1] ===
      //       this.dataAccountActive["business_info"]["my_folder_id"]
      //   );
      //   if (
      //     folder === this.dataAccountActive["business_info"]["my_folder_id"]
      //   ) {
      //     payload = {
      //       user_id: this.dataUsername,
      //       // account_id: this.dataAccountId,
      //       folder_id: folder,
      //       status: status
      //     };
      //   } else if (checkPersonalBusiness !== -1) {
      //     payload = {
      //       user_id: this.dataUsername,
      //       // account_id: this.dataAccountId,
      //       folder_id: folder,
      //       status: status
      //     };
      //   } else {
      //     payload = {
      //       user_id: this.dataUsername,
      //       account_id: this.dataAccountId,
      //       folder_id: folder,
      //       status: status
      //     };
      //   }
      // } else {
      //   payload = {
      //     user_id: this.dataUsername,
      //     account_id: this.dataAccountId,
      //     folder_id: folder,
      //     status: status
      //   };
      // }

      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SHARE_FILE + "/api/search_shared_file",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )

        .then((response) => {
          // console.log("res",response);
          if (response.data.status === "OK") {
            console.log("file", response);
            let i;
            let rawdata = response.data.data;
            this.departmentid = "";
            for (i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              let typefile = rawdata[i]["filename"].split(".");
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["business_id"] = rawdata[i]["business_id"];
              datafolder["business_name_th"] = rawdata[i]["business_name_th"];
              // datafolder["business_id_reciever"] = rawdata[i]["business_id_reciever"];
              datafolder["file_id"] = rawdata[i]["id"];
              datafolder["file_name"] = rawdata[i]["filename"];
              datafolder["file_size"] = rawdata[i]["size_file"];
              // datafolder["file_lastdtm"] = this.formatdatetime(response.data.data[i]["last_dtm"]);
              datafolder["file_lastdtm"] = rawdata[i]["last_dtm"];
              datafolder["file_date"] = "";
              datafolder["file_status"] = rawdata[i]["status_file"];
              datafolder["file_owner_eng"] = rawdata[i]["name_eng"];
              datafolder["file_owner_th"] = rawdata[i]["name_th"];
              datafolder["file_linkshare"] = rawdata[i]["link"];
              datafolder["file_status_share"] = rawdata[i]["status_share"];
              datafolder["file_status_sharelink"] =
                rawdata[i]["status_share_link"];
              datafolder["file_type"] = typefile[typefile.length - 1];
              datafolder["file_department"] = "";
              datafolder["file_permission"] = "";
              datafolder["file_permission_2"] = this.permission;
              datafolder["permission_account"] = this.permission;
              datafolder["status_lock_data"] = rawdata[i].status_lock_file;
              datafolder["relation_status"] = rawdata[i]["relation_status"];
              datafolder["relation_name"] = rawdata[i]["relation_name"];
              datafolder["relation_id"] = rawdata[i]["relation_id"];
              datafolder["status_check"] = rawdata[i]["status"];
              datafolder["file_icon"] = this.seticon(
                typefile[typefile.length - 1]
              );
              datafolder["starred_id"] = rawdata[i]["starred_id"];
              datafolder["type_data"] = "file";
              datafolder["editing_status"] = rawdata[i]["editing_status"];
              datafolder["last_update_name"] = rawdata[i]["last_update_name"];
              datafolder["feature_shared_file"] = rawdata[i]["business_feature"];
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
            }
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            console.log("rootfileeeeeeefileee", this.rootfile);
            this.countfile = response.data.data.length;
            this.loaddataprogress = false;
            this.loadingdata = false;
          } else {
            this.loadingdata = false;
            this.loaddataprogress = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.loadingdata = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเรียกไฟล์ได้ในขณะนี้"
            title: this.$t("toast.cannotopenfile"),
          });
        });
    },
    changepage() {
      this.loaddataprogress = true;
      this.loadsharefolderpagination(false);
    },
    async loadsharefolderpagination(checkfirst) {
      if (checkfirst === true) {
        this.page = 1;
        this.checkpage = 0;
        this.pagefile = 1;
        this.limitfile = 0;
      }
      this.heardertextsearch = false;
      this.get_permission = sessionStorage.getItem("permission");
      // console.log("-----------",this.get_permission);
      this.permission = JSON.parse(this.get_permission);
      // console.log("jsonparse",this.permission);

      // alert("Old function");
      var payload;
      this.loaddataprogress = true;
      this.rootfile = [];
      this.buff = [];
      // if(this.typesharefile === 'ไฟล์แชร์ร่วมกัน'){
      //   var payload = {
      //         user_id: this.dataUsername,
      //         account_id: this.dataAccountId,
      //         folder_id: this.$route.params.id,
      //         status: "",
      //   };
      // }else if(this.typesharefile === 'ไฟล์ที่ซ่อน'){
      //   var payload = {
      //         user_id: this.dataUsername,
      //         account_id: this.dataAccountId,
      //         folder_id: this.$route.params.id,
      //         status: "T",
      //   };
      // }
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      if (this.typesharefile.name === "filesharewithme") {
        var payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.$route.params.id,
          status: "",
          limit: cal_limit,
          offset: cal_offset,
          sort_by: this.sortAndDirection.sort_by === "name" ? "folder_name" : this.sortAndDirection.sort_by,
          direction: this.sortAndDirection.direction,
        };
      } else if (this.typesharefile.name === "hiddenfile") {
        var payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.$route.params.id,
          status: "T",
          limit: cal_limit,
          offset: cal_offset,
          sort_by: this.sortAndDirection.sort_by === "name" ? "folder_name" : this.sortAndDirection.sort_by,
          direction: this.sortAndDirection.direction,
        };
      }
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      let auth = await gbfGenerate.generateToken();
      let url = this.sortAndDirection.sort_by === "size_file" ? "/api/search_shared_file" : "/api/search_shared_folder"
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SHARE_FOLDER +
            url,
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            let type =  this.sortAndDirection.sort_by === "size_file" ? "file" : "folder";
            this.createdata(response, type);
            let total_folder_current = cal_limit + cal_offset;
            let count = this.sortAndDirection.sort_by === "size_file" ? response.data.count_file : response.data.count_folder;
            if (count < total_folder_current) {
              this.checkpage =  Math.floor(count/this.size)
              this.limitfile = Math.abs(this.size - (count % this.size))
              let limit = Math.abs(this.rootfile.length - this.size)
              this.loadsharefilepagination(limit, this.$route.params.id);
            } else {
              this.loadsharefilepagination(0);
            }
            // this.loaddataprogress = false;
            this.sortAndDirection.sort_by === "size_file" ? this.countfile = response.data.count_file : this.countfolder = response.data.count_folder;
          } else {
            this.loadsharefilepagination(cal_limit);
            this.sortAndDirection.sort_by === "size_file" ? this.countfile = response.data.count_file : this.countfolder = response.data.count_folder;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.loadingdata = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้",
          });
        });
    },
    async loadsharefilepagination(cal_limit, folder) {
      let cal_offset;
      if (cal_limit !== this.size && cal_limit !== 0) {
        this.pagefile = Math.abs(this.page - this.checkpage)
        cal_offset = this.size * (this.pagefile - 1);
      } else {
        this.pagefile = Math.abs(this.page - this.checkpage)
        cal_offset = (this.size) * (this.pagefile - 1);
        let rag_offset = Math.abs(this.limitfile - this.size);
        cal_offset = cal_offset - rag_offset < 0 ? 0 : cal_offset - rag_offset;
      }
      // this.checkpage = 0;
      // this.pagefile = 1;
      let payload = {}
      if (this.typesharefile.name === "filesharewithme") {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: folder,
          status: "",
          limit: cal_limit,
          offset: cal_offset,
          sort_by: this.sortAndDirection.sort_by === "name" ? "filename" : this.sortAndDirection.sort_by === "size_file" ? "folder_name" : this.sortAndDirection.sort_by ,
          direction: this.sortAndDirection.direction,
        };
      } else if (this.typesharefile.name === "hiddenfile") {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: folder,
          status: "T",
          limit: cal_limit,
          offset: cal_offset,
          sort_by: this.sortAndDirection.sort_by === "name" ? "filename" : this.sortAndDirection.sort_by === "size_file" ? "folder_name" : this.sortAndDirection.sort_by ,
          direction: this.sortAndDirection.direction,
        };
      }
      let auth = await gbfGenerate.generateToken();
      let url = this.sortAndDirection.sort_by === "size_file" ? "/api/search_shared_folder" : "/api/search_shared_file"
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE + url,
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            let type =  this.sortAndDirection.sort_by === "size_file" ? "folder" : "file";
            this.createdata(response, type);
            this.loaddataprogress = false;
            this.loadingdata = false;
            this.sortAndDirection.sort_by === "size_file" ? this.countfolder = response.data.count_folder : this.countfile = response.data.count_file;
          } else {
            this.loaddataprogress = false;
            this.loadingdata = false;
            this.sortAndDirection.sort_by === "size_file" ? this.countfolder = response.data.count_folder : this.countfile = response.data.count_file;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.loadingdata = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    createdata(response, type) {
      if (type === "folder") {
        if (response.data.your_folder[0]["status_lock_folder"] === "Y") {
          this.opendialog_checklog = true;
          let folderdata = {};
          folderdata["file_id"] = response.data.your_folder[0]["folder_id"];
          this.newdata = folderdata;
        }
        console.log("rrrr", response);
        let checknavigate = this.navigate.findIndex(
          (y) => y.text[1] === this.$route.params.id
        );
        if (checknavigate === -1) {
          if (this.dataAccountActive["type"] === "Business") {
            if (
              response.data.your_folder[0]["folder_id"] ===
              this.dataAccountActive["business_info"]["my_folder_id"]
            ) {
              this.navigate.push({
                text: [
                  this.$store.getters.dataTypeusercurrent(
                    this.$store.state.account_active["type"],
                    this.$t("default")
                  ),
                  response.data.your_folder[0]["folder_id"],
                ],
                disabled: false,
              });
            } else {
              this.navigate.push({
                text: [
                  response.data.your_folder[0]["folder_name"],
                  response.data.your_folder[0]["folder_id"],
                ],
                disabled: false,
              });
            }
          } else {
            this.navigate.push({
              text: [
                response.data.your_folder[0]["folder_name"],
                response.data.your_folder[0]["folder_id"],
              ],
              disabled: false,
            });
          }
        } else {
          let datanavigate = this.navigate;
          let i;
          this.navigate = [];
          for (i = 0; i <= checknavigate; i++) {
            this.navigate.push(datanavigate[i]);
          }
        }
        this.foldername = response.data.your_folder[0]["folder_name"];
        this.system = response.data.your_folder[0]["system"];
        this.status_aspect_key =
          response.data.your_folder[0]["status_aspect_key"];
        this.folderid = response.data.your_folder[0]["folder_id"];
        this.departmentid = "";
        this.permission_currentfolder = this.permission;
        if (
          response.data.your_folder[0]["system"] !== null &&
          response.data.your_folder[0]["status_aspect_key"] === "Y"
        ) {
          localStorage.setItem("changeStoreUI", "false");
          localStorage.setItem("changeUI", "false");
          this.changeUI = false;
          this.getdoctype();
        }
        var i;
        for (i = 0; i < response.data.folder.length; i++) {
          let datafolder = {};
          datafolder["account_reciever"] = "";
          datafolder["account_sender"] = this.dataAccountId;
          // datafolder["business_id_reciever"] = response.data.folder[i]["business_id_reciever"];
          datafolder["file_id"] = response.data.folder[i]["folder_id"];
          datafolder["file_name"] = response.data.folder[i]["folder_name"];
          datafolder["file_size"] = "-";
          datafolder["file_lastdtm"] =
            response.data.folder[i]["cre_dtm"] || "-";
          datafolder["file_date"] = "";
          datafolder["file_linkshare"] = response.data.folder[i]["link"];
          datafolder["file_status_share"] =
            response.data.folder[i]["status_share"];
          datafolder["file_status_sharelink"] =
            response.data.folder[i]["status_share_link"];
          datafolder["file_status"] =
            response.data.folder[i]["folder_status"];
          datafolder["file_owner_eng"] =
            response.data.folder[i]["name_eng"];
          datafolder["file_owner_th"] = response.data.folder[i]["name_th"];
          datafolder["file_type"] = "folder";
          datafolder["file_department"] = "";
          datafolder["file_permission"] = "";
          datafolder["file_permission_2"] = this.permission;
          datafolder["permission_account"] = this.permission;
          datafolder["status_lock_data"] =
            response.data.folder[i].status_lock_folder === null
              ? response.data.folder[i].status_lock_file
              : response.data.folder[i].status_lock_folder;
          datafolder["file_icon"] = ["folder", "#ffc107"];
          datafolder["relation_status"] =
            response.data.folder[i]["relation_status"];
          datafolder["relation_name"] =
            response.data.folder[i]["relation_name"];
          datafolder["relation_id"] =
            response.data.folder[i]["relation_id"];
          datafolder["status_check"] = response.data.folder[i]["status"];
          datafolder["starred_id"] = response.data.folder[i]["starred_id"];
          datafolder["type_data"] = "folder";
          datafolder["editing_status"] = "";
          datafolder["last_update_name"] = response.data.folder[i]["last_update_name"];
          this.rootfile.push(datafolder);
          this.buff.push(datafolder);
        }
        if (this.clickSort === true) {
          this.rootfile.push(this.sortFile(this.rootfile));
        }
        if (this.system !== null && this.status_aspect_key === "Y") {
          this.getdoctype();
        }
      } else {
        console.log("file", response);
        let i;
        let rawdata = response.data.data;
        this.departmentid = "";
        for (i = 0; i < response.data.data.length; i++) {
          let datafolder = {};
          let typefile = rawdata[i]["filename"].split(".");
          datafolder["account_reciever"] = "";
          datafolder["account_sender"] = this.dataAccountId;
          datafolder["business_id"] = rawdata[i]["business_id"];
          datafolder["business_name_th"] = rawdata[i]["business_name_th"];
          // datafolder["business_id_reciever"] = rawdata[i]["business_id_reciever"];
          datafolder["file_id"] = rawdata[i]["id"];
          datafolder["file_name"] = rawdata[i]["filename"];
          datafolder["file_size"] = rawdata[i]["size_file"];
          // datafolder["file_lastdtm"] = this.formatdatetime(response.data.data[i]["last_dtm"]);
          datafolder["file_lastdtm"] = rawdata[i]["last_dtm"];
          datafolder["file_date"] = "";
          datafolder["file_status"] = rawdata[i]["status_file"];
          datafolder["file_owner_eng"] = rawdata[i]["name_eng"];
          datafolder["file_owner_th"] = rawdata[i]["name_th"];
          datafolder["file_linkshare"] = rawdata[i]["link"];
          datafolder["file_status_share"] = rawdata[i]["status_share"];
          datafolder["file_status_sharelink"] =
            rawdata[i]["status_share_link"];
          datafolder["file_type"] = typefile[typefile.length - 1];
          datafolder["file_department"] = "";
          datafolder["file_permission"] = "";
          datafolder["file_permission_2"] = this.permission;
          datafolder["permission_account"] = this.permission;
          datafolder["status_lock_data"] = rawdata[i].status_lock_file;
          datafolder["relation_status"] = rawdata[i]["relation_status"];
          datafolder["relation_name"] = rawdata[i]["relation_name"];
          datafolder["relation_id"] = rawdata[i]["relation_id"];
          datafolder["status_check"] = rawdata[i]["status"];
          datafolder["file_icon"] = this.seticon(
            typefile[typefile.length - 1]
          );
          datafolder["starred_id"] = rawdata[i]["starred_id"];
          datafolder["type_data"] = "file";
          datafolder["editing_status"] = rawdata[i]["editing_status"];
          datafolder["last_update_name"] = rawdata[i]["last_update_name"];
          datafolder["feature_shared_file"] = rawdata[i]["business_feature"];
          this.rootfile.push(datafolder);
          this.buff.push(datafolder);
        }
        if (this.clickSort === true) {
          this.rootfile.push(this.sortFile(this.rootfile));
        }
      }
    },
    // กำหนดไอคอน
    seticon(parameter) {
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls" || parameter === "xlsb") {
        dataicon = ["mdi-file-excel", "#00733b"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "#0263d1"];
      } else if (parameter === "html") {
        dataicon = ["mdi-language-html5", "#FF603F"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "#e03303"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "#e5252a"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
        } else if (parameter === "csv") {
        dataicon = ["mdi-file-table", "green"];
        }else if (parameter === "zip") {
        dataicon = ["mdi-zip-box", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image", "#0ac963"];
      } else if (parameter === "r" || parameter === "folder") {
        dataicon = ["mdi-folder", "#ffc107"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else {
        dataicon = ["mdi-file", "black"];
      }

      return dataicon;
    },
    loadstorage() {
      this.$store
        .dispatch("check_storage", this.dataAccountId)
        .then((res) => {});
    },
    backtopreventfolder() {
      this.opendialog_checklog = false;
      this.navigate.pop();
      if (this.navigate.length === 1) {
        this.loadingdata = true;
        this.rootfile = [];
        this.buff = [];
        this.$router.replace("/sharedwithme");
      } else {
        this.loadingdata = true;
        this.$router.replace({
          path:
            "/directorysharefile/" +
            this.navigate[this.navigate.length - 1]["text"][1],
        });
        this.loadsharefolderpagination(true);
        // this.loadfileandfolder();
        this.parentfolder = this.$route.params.id;
      }
    },
    gotofolder(_folderid) {
      if (_folderid === this.dataAccountActive["directory_id"]) {
        this.loadingdata = true;
        this.rootfile = [];
        this.buff = [];
        this.$router.replace("/sharedwithme");
      } else {
        this.loadingdata = true;
        this.$router.replace({ path: "/directorysharefile/" + _folderid });
        this.loadsharefolderpagination(true);
        this.parentfolder = this.$route.params.id;
      }
    },
  },
  mounted() {
    var self = this;
    // this.loadfileandfolder()

    //   window.addEventListener("dragenter", function(e) {
    //   document.querySelector("#dropzone").style.visibility = "";
    //   document.querySelector("#dropzone").style.opacity = 1;
    //   document.querySelector("#textnode").style.fontSize = "48px";
    // });

    // window.addEventListener("dragleave", function(e) {
    //   e.preventDefault();

    //   document.querySelector("#dropzone").style.visibility = "hidden";
    //   document.querySelector("#dropzone").style.opacity = 0;
    //   document.querySelector("#textnode").style.fontSize = "42px";
    // });

    // window.addEventListener("dragover", function(e) {
    //   e.preventDefault();
    //   document.querySelector("#dropzone").style.visibility = "";
    //   document.querySelector("#dropzone").style.opacity = 1;
    //   document.querySelector("#textnode").style.fontSize = "48px";
    // });

    // window.addEventListener("drop", function(e) {
    //   e.preventDefault();
    //   document.querySelector("#dropzone").style.visibility = "hidden";
    //   document.querySelector("#dropzone").style.opacity = 0;
    //   document.querySelector("#textnode").style.fontSize = "42px";

    //   var files = e.dataTransfer.files;
    //   console.log("Drop files:", files);
    //this.uploadFile(files);
    //   self.onDrop(files);

    // });

    self.loadstorage();
    if (
      this.dataDepartmentAccessId === "" &&
      this.dataAccountActive["type"] === "Business"
    ) {
      let a = this.dataAccountActive["directory_business"].findIndex(
        (a) => a.department_folder_id === this.$route.params.id
      );
      if (a !== -1) {
        this.$store.dispatch(
          "department_access_id",
          this.dataAccountActive["directory_business"][a]["department_id"]
        );
      }
      if (
        sessionStorage.getItem("permission") === undefined ||
        sessionStorage.getItem("permission") === "" ||
        sessionStorage.getItem("permission") === null
      ) {
        this.$router.replace({ path: "/sharedwithme/" });
      } else {
        this.loadsharefolderpagination(true);
      }
      // this.loadfolder();
      // this.loadfileandfolder();
    } else {
      if (
        sessionStorage.getItem("permission") === undefined ||
        sessionStorage.getItem("permission") === "" ||
        sessionStorage.getItem("permission") === null
      ) {
        this.$router.replace({ path: "/sharedwithme/" });
      } else {
        this.loadsharefolderpagination(true);
      }
      // this.loadfolder();
      // this.loadfileandfolder();
    }

    this.navigate.push({
      text: [
        "Share with me",
        this.dataAccountActive["directory_id"],
        "แชร์ร่วมกัน",
      ],
      disabled: false,
    });
  },
  created() {},
};
</script>

<style>
#demo {
  width: 100%;
  height: 100%;
}

div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}

div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  font: bold 42px Oswald, DejaVu Sans, Tahoma, sans-serif;
}

#dropzone {
  min-height: 100%;
}

#thiscontainer_directorysharefile {
  height: calc(101vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.pointer {
  cursor: pointer;
}

.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
</style>